import { useEffect, useMemo, useState } from "react";
import { Button as PrimeReactButton } from "primereact/button";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import { useFormik } from "formik";
import { ADD_CATEGORY_VALIDATION } from "../../utils/validations";
import Dropdown from "../../components/Dropdown";
import {
  addCategory,
  addSubCategory,
  deleteCategory,
  deleteSubCategory,
  getCategories,
  updateCategory,
  updateSubCategory,
} from "../../controller/CategoriesController";
import { toast } from "../../utils/helper";
import ViewCategory from "../../components/ViewCategory";
import { Animations } from "../../animations";
import RenderLottie from "../../components/RenderLottie";
import Loader from "../../components/Loader";
import DataOverview from "../../components/DataOverview";
import { images } from "../../assets";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { colors } from "../../themes/colors";
import { Image } from "primereact/image";
import ActionPopup from "../../components/ActionPopup";
import { uploadFile } from "../../controller/FilesController";

type Props = {};

const Categories = (props: Props) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubCategoryEdit, setIsSubCategoryEdit] = useState(false);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [totalCategories, setTotalCategories] = useState("");
  const [totalSubCategories, setTotalSubCategories] = useState("");

  const [editCategoryId, setEditCategoryId] = useState("");
  const [categoryImage, setCategoryImage] = useState<any>("");
  const [imageError, setImageError] = useState("");
  const [perentCategoryId, setperentCategoryId] = useState("");
  const [EditCategoryImage, setEditCategoryImage] = useState({
    Image: "",
    Url: "",
    Value: false,
  });

  const initialValues = useMemo(() => {
    if (dropdownData.length > 0) {
      return {
        categoryName: "",
        parentCategory: "",
      };
    }
    return {
      categoryName: "",
    };
  }, [dropdownData]);

  useEffect(() => {
    setIsSomethingWentWrong(false);
    setLoading(false);
    getExistingCategories();
  }, []);

  const getExistingCategories = async () => {
    try {
      const response = await getCategories();

      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      console.log(response.data);

      setCategoriesData(response?.data);
      const preparedData = response?.data.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      const totalCategoriesCount = response?.data.length;
      const totalSubCategoriesCount = response?.data.reduce(
        (acc: any, item: any) => acc + item.subCategory.length,
        0
      );

      setDropdownData(preparedData);
      setTotalCategories(totalCategoriesCount);
      setTotalSubCategories(totalSubCategoriesCount);
    } catch (error) {
      console.log("error in getting categories :>> ", error);
      setIsSomethingWentWrong(true);
    } finally {
      setLoading(false);
    }
  };

  const onAddNewCategory = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    handleReset(() => {});
    setCategoryImage("");
  };

  const handleDropdownChange = (selectedOption: any) => {
    const obj = {
      target: {
        name: "parentCategory",
        value: selectedOption,
      },
    };
    setperentCategoryId(selectedOption.value);
    handleChange(obj);
  };

  const imageUpload = async () => {
    let preparedImage = new FormData();
    preparedImage.append("file", categoryImage);
    const response = await uploadFile(preparedImage);
    return response.key;
  };

  const { values, errors, touched, handleChange, handleSubmit, handleReset } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: ADD_CATEGORY_VALIDATION,
      onSubmit: () => {
        if (imageError) {
          return;
        }
        handleSave();
      },
    });

  const validateInputs = () => {
    if (!categoryImage) {
      setImageError("Required");
    } else {
      setImageError("");
    }

    handleSubmit();
  };

  const handleSave = async () => {
    try {
      setButtonLoading(true);
      let image;
      let preparedData;

      !EditCategoryImage.Value && (image = await imageUpload());

      EditCategoryImage.Value
        ? (preparedData = {
            name: values.categoryName,
            image: EditCategoryImage.Image,
          })
        : (preparedData = {
            name: values.categoryName,
            image: image,
          });

      let addCategoryFunction;

      console.log("values :>> ", values);

      if (values.parentCategory || isSubCategoryEdit) {
        EditCategoryImage.Value
          ? (preparedData = {
              name: values.categoryName,
              image: EditCategoryImage.Image,
              categoryId: perentCategoryId,
            })
          : (preparedData = {
              name: values.categoryName,
              image: image,
              categoryId: perentCategoryId,
            });

        addCategoryFunction = isEditMode ? updateSubCategory : addSubCategory;
      } else {
        addCategoryFunction = isEditMode ? updateCategory : addCategory;
      }

      console.log(preparedData);

      const response = await addCategoryFunction(preparedData, editCategoryId);

      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      handleCloseModal();
    } catch (error) {
      console.log("error in saving data :>> ", error);
    } finally {
      setButtonLoading(false);
      setCategoryImage("");
      if (isEditMode) {
        setIsEditMode(false);
        setEditCategoryId("");
        setIsSubCategoryEdit(false);
      }
      getExistingCategories();
    }
  };

  const handleEdit = async (item: any, isSubCategory: boolean) => {
    try {
      setIsEditMode(true);
      setEditCategoryId(item.id);
      setIsSubCategoryEdit(isSubCategory);

      handleChange("categoryName")(item?.name);

      const imageUrl = item?.imageUrl;

      setCategoryImage(imageUrl);
      setEditCategoryImage({
        Value: true,
        Image: item.image,
        Url: item.imageUrl,
      });

      setShowModal(true);
    } catch (error) {
      console.log("error in editing category :>> ", error);
      toast.error("Something went wrong");
      handleReset(() => {});
      setEditCategoryId("");
      setIsEditMode(false);
      setIsSubCategoryEdit(false);
      setCategoryImage("");
      setShowModal(false);
    }
  };

  const handleDelete = (
    id: string,
    isSubCategory: boolean,
    hasSubCategoryData?: boolean
  ) => {
    if (hasSubCategoryData) {
      toast.error("This category has sub categories. Please delete them first");
      return;
    }

    setIsSubCategoryEdit(isSubCategory);
    setEditCategoryId(id);
    setDeletePopupVisible(true);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages: Blob[] = Array.from(files);
      setCategoryImage(newImages[0]);
    }
  };

  const onDeleteImage = () => {
    setCategoryImage("");
    if (EditCategoryImage) {
      setEditCategoryImage({ Value: false, Image: "", Url: "" });
    }
  };

  const onDelete = async () => {
    try {
      const response = isSubCategoryEdit
        ? await deleteSubCategory(editCategoryId)
        : await deleteCategory(editCategoryId);

      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      toast.success("Category deleted successfully");

      await getExistingCategories();
    } catch (error) {
      console.log("error in deleting category :>> ", error);
    } finally {
      setDeletePopupVisible(false);
      setIsSubCategoryEdit(false);
      setEditCategoryId("");
    }
  };

  const hideDeletePopup = () => {
    setDeletePopupVisible(false);
    setIsSubCategoryEdit(false);
    setEditCategoryId("");
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      {isSomethingWentWrong ? (
        <RenderLottie
          animationData={Animations.SomethingWentWrong}
          title="Something Went Wrong"
          message="Please try again later or contact support for assistance."
        />
      ) : (
        <div className="px-4 py-3">
          <h4>Categories</h4>
          {/* <Header title="Categories" onButtonClick={onAddNewCategory} /> */}

          <div className="d-flex mt-5">
            <DataOverview
              title="Total Categories"
              value={totalCategories}
              icon={images.Basket}
              iconBgColor={colors.pink}
              title2="Total Sub Categories"
              value2={totalSubCategories}
              icon2={images.Boxes}
              iconBgColor2={colors.lightPurple}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center	my-5">
            <div className="search-container w-50">
              <input
                type="search"
                className="search-input outline-none border rounded-3 p-2 px-3"
                placeholder="Search category or sub category"
              />
              <FontAwesomeIcon
                icon={faSearch}
                color="#9F9F9F"
                className="mr-2 search-icon"
              />
            </div>

            <Button
              variant="danger"
              onClick={onAddNewCategory}
              className="ml-3"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Category
            </Button>
          </div>

          <div>
            {categoriesData.length === 0 ? (
              <RenderLottie
                animationData={Animations.NoDataFound}
                title="No Categories Found"
                message="Please add a new category to get started"
              />
            ) : (
              <ViewCategory
                data={categoriesData}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
          </div>
        </div>
      )}
      <ActionPopup
        visible={deletePopupVisible}
        title=""
        message="Are you sure you want to delete this category?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={onDelete}
        onCancel={hideDeletePopup}
      />

      <Modal
        show={showModal}
        title={isEditMode ? "Update Category" : "Add Category"}
        handleClose={handleCloseModal}
        handleSave={validateInputs}
        loading={buttonLoading}
        btnName={isEditMode ? "Edit Category" : "Add Category"}
        size="lg"
        body={
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Input
                label="Category Name"
                placeholder="Enter a category name"
                name="categoryName"
                value={values.categoryName}
                onChange={handleChange}
                error={
                  touched.categoryName && errors.categoryName
                    ? errors.categoryName
                    : ""
                }
                autoFocus
              />

              {dropdownData.length > 0 && !isEditMode && (
                <Dropdown
                  label="Parent Category"
                  placeholder="Select parent category"
                  options={dropdownData}
                  containerClassName="mt-4"
                  value={values.parentCategory}
                  name="parentCategory"
                  onChange={handleDropdownChange}
                  error=""
                />
              )}
            </div>

            <div>
              {!categoryImage ? (
                <div>
                  <label
                    htmlFor="image-upload"
                    className="border-dashed border-light-subtle rounded d-flex flex-column justify-content-center align-items-center bg-white"
                    style={{
                      height: "200px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      id="image-upload"
                      style={{ visibility: "hidden" }}
                    />
                    <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" />
                    <p className="w-75 text-center text-secondary">
                      Drag and drop your image here or click to upload
                    </p>
                  </label>
                  {imageError && (
                    <small className="text-danger">{imageError}</small>
                  )}
                </div>
              ) : (
                <div className="image-upload">
                  <div className="image-preview">
                    <div className="">
                      <Image
                        // src={URL.createObjectURL(categoryImage)}
                        src={
                          EditCategoryImage.Value
                            ? categoryImage
                            : URL.createObjectURL(categoryImage)
                        }
                        alt={`Category`}
                        width="300"
                        height="200"
                        preview
                        style={{
                          objectFit: "contain",
                        }}
                      />
                      <PrimeReactButton
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger p-button-outlined p-button-sm p-button-text"
                        onClick={onDeleteImage}
                      />
                    </div>
                  </div>
                  {/* <input
										type="file"
										accept="image/*"
										onChange={handleImageChange}
										id="image-upload"
										style={{ display: "none" }}
										multiple
									/>
									<label
										htmlFor="image-upload"
										className="border border-light-subtle rounded px-2 py-1 text-center bg-white"
										style={{ cursor: "pointer", marginTop: "10px" }}
									>
										<span className="pi pi-plus" />
									</label> */}
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Categories;
