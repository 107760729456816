import { Table, Image } from "react-bootstrap";
import { images } from "../../assets";

interface data {
	size: Number;
}
const ProductTable = (props: data) => {
	return (
		<Table bordered>
			<thead>
				<tr className="fw-semibold">
					<td>Item Details</td>
					<td className="text-right">Quantity</td>
					<td className="text-right">Rate</td>
					<td className="text-right">Amount</td>
				</tr>
			</thead>
			<tbody>
				{[...Array(props.size)].map((_, idx) => (
					<>
						<tr key={idx} style={{ borderBottom: "10px solid #EFEFEF" }}>
							<td className="w-5">
								<div className="d-flex align-items-center">
									<Image
										src={images.Rice}
										height={45}
										width={45}
										className="border rounded-3 border-2"
										style={{
											marginRight: "10px",
										}}
									/>
									<div className="border mx-2" style={{ height: "40px" }}></div>
									<div className="w-100 flex align-items-center justify-content-between gap-1">
										Organic Sonamasuri Brown Rice
										<div className="flex gap-1">
											<img src={images.more} alt="" />
											<img src={images.cancle} alt="" />
										</div>
									</div>
								</div>
							</td>
							<td className="text-right">
								<div className="flex flex-column text-sm align-items-end">
									<span className="fw-semibold" style={{ fontSize: "20px" }}>
										50
									</span>
									<span style={{ color: "#53545C" }}>Stock in Hand: 15</span>
								</div>
							</td>
							<td className="text-sm text-right">
								<div
									className="flex align-items-center flex-row-reverse"
									style={{ height: "40px" }}
								>
									₹ 320
								</div>
							</td>
							<td className="text-sm fw-semibold text-right">
								<div
									className="flex align-items-center flex-row-reverse"
									style={{ height: "40px" }}
								>
									16,000.00
								</div>
							</td>
						</tr>
					</>
				))}
			</tbody>
		</Table>
	);
};

export default ProductTable;
