import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./login.css";

import Button from "../../components/Button";
import Input from "../../components/Input";

import { loginUser } from "../../services/AuthService";

import {
  // getUserData,
  getUserId,
  toast,
  updateUserAuthenticationStatus,
} from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

interface LoginProps {}

const Login = (props: LoginProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    let id;
    let a = async () => {
      id = await getUserId();
      if (id) {
        navigate(ROUTES.MAIN, { replace: true });
      }
    };
    a();
  }, [navigate]);

  const [formValues, setFormValues] = useState({
    emailOrMobile: "",
    password: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    emailOrMobile: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    setErrorMessages((prevState) => ({
      ...prevState,
      [e.target.id]: "",
    }));
  };

  const validateInputs = () => {
    let isValid = true;
    let errorMEssages = {
      emailOrMobile: "",
      password: "",
    };

    if (
      !formValues.emailOrMobile ||
      (formValues.emailOrMobile && formValues.emailOrMobile.trim().length === 0)
    ) {
      isValid = false;
      errorMEssages.emailOrMobile =
        "Please enter your email address or mobile number";
    } else if (
      !formValues.emailOrMobile.includes("@") &&
      isNaN(parseInt(formValues.emailOrMobile))
    ) {
      isValid = false;
      errorMEssages.emailOrMobile =
        "Please enter a valid email address or mobile number";
    }

    if (
      !formValues.password ||
      (formValues.password && formValues.password.trim().length === 0)
    ) {
      isValid = false;
      errorMEssages.password = "Please enter your password";
    }

    setErrorMessages(errorMEssages);

    return isValid;
  };

  const onBtnPress = () => {
    setLoading(true);
    const isValid = validateInputs();
    if (isValid) {
      login();
    } else {
      setLoading(false);
    }
  };

  const login = async () => {
    try {
      const preparedData = {
        email: formValues.emailOrMobile,
        password: formValues.password,
      };
      const response = await loginUser(preparedData);
      console.log("response :>> ", response);

      if (response && response.statusCode === 400) {
        toast.error(response.message);
        return;
      }

      await updateUserAuthenticationStatus(true, JSON.stringify(response.data));

      navigate(ROUTES.MAIN, { replace: true });
    } catch (error) {
      console.log("error in login :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-body-tertiary">
      <div
        className="shadow p-5 rounded bg-white"
        style={{
          width: "40vw",
          // height: "60vh",
        }}
      >
        <h2>Login</h2>
        <p>to access dashboard</p>
        <form>
          <div className="mb-3">
            <Input
              type="text"
              id="emailOrMobile"
              aria-describedby="emailOrMobileHelp"
              placeholder="Email address or mobile number"
              onChange={onInputChange}
              value={formValues.emailOrMobile}
              error={errorMessages.emailOrMobile}
            />
            <Input
              isPassword
              id="password"
              aria-describedby="password"
              placeholder="Enter password"
              className="mt-3"
              onChange={onInputChange}
              value={formValues.password}
              error={errorMessages.password}
            />
          </div>

          <Button
            type="submit"
            className="w-100 mt-3"
            onClick={onBtnPress}
            isLoading={loading}
          >
            Login
          </Button>
        </form>

        <div className="mt-3">
          <NavLink
            to="/messages"
            className="text-decoration-none text-end d-flex justify-content-end"
          >
            Forgot Password?
          </NavLink>
          <div className="mt-3 d-flex">
            <p className="me-1">Don't have an Urbanlyfe Account? </p>
            <NavLink to={ROUTES.REGISTER} className="text-decoration-none">
              Register Now
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
