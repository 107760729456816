import {
	faBox,
	faBoxesStacked,
	faFlag,
	faList,
} from "@fortawesome/free-solid-svg-icons";
import { ROUTES } from "../navigators/Routes";

export const STORAGE_KEYS = {
	IS_USER_AUTHENTICATED: "isUserAuthenticated",
	USER_DATA: "userData",
};

export const PAGES = {
	LOGIN: "login",
};

export const SIDE_MENU_ITEMS = [
	{
		id: "1",
		title: "Inventory",
		icon: faBoxesStacked,
		path: ROUTES.MAIN,
		subMenu: [
			{
				id: "1",
				title: "Brands",
				icon: faFlag,
				path: ROUTES.BRANDS,
				subMenu: [],
			},
			{
				id: "2",
				title: "Categories",
				icon: faList,
				path: ROUTES.CATEGORIES,
				subMenu: [],
			},
			{
				id: "3",
				title: "Products",
				icon: faBox,
				path: ROUTES.PRODUCTS,
				subMenu: [],
			},
			{
				id: "4",
				title: "Adjustments",
				icon: faBox,
				path: ROUTES.ADJUSTMENTS,
				subMenu: [],
			},
		],
	},
	{
		id: "2",
		title: "Purchases",
		icon: faBox,
		path: ROUTES.MAIN,
		subMenu: [
			{
				id: "1",
				title: "Brands(Vendors)",
				icon: faList,
				path: ROUTES.VENDOR,
				subMenu: [],
			},
			{
				id: "2",
				title: "Purchase Orders",
				icon: faList,
				path: ROUTES.PURCHASEORDERS,
				subMenu: [],
			},
			{
				id: "3",
				title: "Bills",
				icon: faList,
				path: ROUTES.BILLS,
				subMenu: [],
			},
		],
	},
	//   {
	//     id: "3",
	//     title: "Categories",
	//     icon: faList,
	//     path: ROUTES.CATEGORIES,
	//     subMenu: [],
	//   },
	//   {
	//     id: "4",
	//     title: "Products",
	//     icon: faBox,
	//     path: ROUTES.PRODUCTS,
	//     subMenu: [],
	//   },
	//   {
	//     id: "5",
	//     title: "Adjustments",
	//     icon: faBox,
	//     path: ROUTES.ADJUSTMENTS,
	//     subMenu: [],
	//   },

	// {
	// 	id: "2",
	// 	title: "Inventory",
	// 	icon: faBoxesStacked,
	// 	path: "",
	// 	subMenu: [
	// 		{
	// 			id: "2-1",
	// 			title: "Products",
	// 			path: ROUTES.PRODUCTS,
	// 		},
	// 		{
	// 			id: "2-2",
	// 			title: "Categories",
	// 			path: ROUTES.CATEGORIES,
	// 		},
	// 		{
	// 			id: "2-3",
	// 			title: "Brands",
	// 			path: ROUTES.BRANDS,
	// 		},
	// 	],
	// },
];

export const WEIGHT_UNITS = [
	{
		id: "1",
		name: "kg",
	},
	{
		id: "2",
		name: "g",
	},
	{
		id: "3",
		name: "lb",
	},
	{
		id: "4",
		name: "oz",
	},
];
