import { Image } from "react-bootstrap";
import { images } from "../../assets";

interface Props {
  Data: any;
  onDelete: (item:any) => void;
  onEdit: (id:any) => void;
}

const formatDate = (isoString:any) => {
  const date = new Date(isoString);

  // Extract the components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const isPM = hours >= 12;
  hours = hours % 12 || 12; // Convert to 12-hour format

  const amPm = isPM ? "PM" : "AM";

  return `${year}-${month}-${day} ${hours}:${minutes} ${amPm}`;
};

const ViewAdjustments = (props: Props) => {
  const { Data, onDelete, onEdit } = props;
  return (
    <div className="p-2 flex flex-column gap-2">
      {Data.map((item: any, i: any) => {
        return (
          <div
            key={i}
            className="flex border border-2 gap-2 text-sm text-center align-items-center w-100 rounded-3 p-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <div className="flex gap-2" style={{ width: "6%" }}>
              <input type="checkbox" />
              <span>
                <Image
                  src={item.items[0].product.image}
                  height={59}
                  width={59}
                  className="rounded-3"
                />
              </span>
            </div>

            <div className="flex align-items-center" style={{ width: "40%" }}>
              <span className="w-25">{item.date.split("T")[0]}</span>
              <span className="w-25">{item.reason}</span>
              <span className="w-50 text-left ml-2">{item.description}</span>
            </div>

            <div
              className="flex align-items-center gap-1"
              style={{ width: "48%" }}
            >
              <span
                className="w-2 text-uppercase"
                style={
                  item.status === "1" ? { color: "#4E4E4E" } : { color: "#0077B6" }
                }
              >
                {item.status === "1" ? "Draft" : "Adjusted"}
              </span>
              <span className="w-2">{item.referenceNumber}</span>
              <span className="w-2">{item.modeOfAdjustment === "1" ? "Quantity" : "Value"}</span>
              <span className="w-2">{formatDate(item.createdAt)}</span>
              <span className="w-3">{formatDate(item.updatedAt)}</span>
            </div>
            <div className="d-flex gap-3" style={{ width: "6%" }}>
              <Image
                src={images.Edit}
                height={25}
                width={25}
                onClick={()=>{onEdit(item.id)}}
              />
              <Image
                src={images.Delete}
                height={23}
                width={23}
                onClick={()=>{onDelete(item.id)}}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewAdjustments;
