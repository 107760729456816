import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUserId } from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

type Props = {};

const Dashboard = (props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let id;
    let a = async () => {
      id = await getUserId();
      if (!id) {
        navigate(ROUTES.LOGIN, { replace: true });
      }
    };
    a();
  }, [navigate]);
  return <div>Dashboard</div>;
};

export default Dashboard;
