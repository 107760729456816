import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { colors } from "../../themes/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";

interface HorizontalInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label?: string;
	error?: string;
	isMandatory?: boolean;
	isImportant?: boolean;
	tooltipDescription?: string;
	containerClassName?: string;
	customInput?: React.ReactNode;
}

const HorizontalInput = (props: HorizontalInputProps) => {
	const {
		label,
		error,
		isMandatory,
		isImportant,
		tooltipDescription,
		containerClassName,
		customInput,
		...rest
	} = props;

	const renderTooltip = (props: any) => (
		<Tooltip id="input-tooltip" {...props}>
			{tooltipDescription}
		</Tooltip>
	);

	return (
		<div
			className={`d-flex align-items-start ${
				containerClassName ? containerClassName : ""
			}`}
		>
			{/* {label && (
				<div
					className="d-flex align-items-center mt-1"
					style={{
						width: "10rem",
						color: isImportant ? colors.red : colors.lightBlack,
					}}
				>
					<label htmlFor={rest.id} className="form-label mb-0">
						{label}
					</label>
					{isMandatory && <span>*</span>}
					{tooltipDescription && (
						<OverlayTrigger placement="right" overlay={renderTooltip}>
							<FontAwesomeIcon
								icon={faCircleQuestion}
								size="xs"
								className="ms-1"
							/>
						</OverlayTrigger>
					)}
				</div>
			)} */}
			<div
				className="w-100"
			>
				<div
					className="d-flex align-items-center mt-1"
					style={{
						width: "10rem",
						color: isImportant ? colors.red : colors.lightBlack,
					}}
				>
					<label htmlFor={rest.id} className="form-label mb-0">
						{label}
					</label>
					{isMandatory && <span>*</span>}
					{tooltipDescription && (
						<OverlayTrigger placement="right" overlay={renderTooltip}>
							<FontAwesomeIcon
								icon={faCircleQuestion}
								size="xs"
								className="ms-1"
							/>
						</OverlayTrigger>
					)}
				</div>
				{customInput ? (
					customInput
				) : (
					<input className={`form-control`} id={rest.id} {...rest} />
				)}
				{error && <small className="text-danger">{error}</small>}
			</div>
		</div>
	);
};

export default HorizontalInput;
