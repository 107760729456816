import UIButton, { ButtonProps } from "react-bootstrap/Button";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/esm/helpers";
import React from "react";
import { Spinner } from "react-bootstrap";

interface Props extends ButtonProps {
	children: React.ReactNode | React.ReactNode[] | string;
	isLoading?: boolean;
}

const Button: BsPrefixRefForwardingComponent<"button", Props> = (
	props: Props
) => {
	const { children, isLoading, ...rest } = props;
	return (
		<UIButton
			variant="primary"
			{...rest}
			onClick={(e) => {
				e.preventDefault();
				!isLoading && rest.onClick && rest.onClick(e);
			}}
			disabled={isLoading || rest.disabled}
		>
			{isLoading ? (
				<Spinner
					as="span"
					animation="border"
					size="sm"
					role="status"
					aria-hidden="true"
				/>
			) : (
				children
			)}
		</UIButton>
	);
};

export default Button;
