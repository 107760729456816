import { CloseButton, Dropdown } from "react-bootstrap";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFilePdf,
  faPen,
  faPlus,
  faReceipt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useRef } from "react";
import Details from "../../components/Vendo&ProductDetailsTable/VendorDetailsTable";
import ProductTable from "../../components/Vendo&ProductDetailsTable/ProductTable";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";
import { Dialog, DialogContent } from "@mui/material";
import { Image } from "primereact/image";
import { images } from "../../assets";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const EditPurchaseOrder = () => {
  const [isOn, setIsOn] = useState(false);
  const [Draft, setDraft] = useState(false);
  const [Popup, setPopup] = useState(false);

  const [pdfSrc, setPdfSrc] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const handleToggle = () => {
    if (isOn) {
      setIsOn(false);
      if (pdfSrc) URL.revokeObjectURL(pdfSrc);
      setPdfSrc(null);
    } else {
      setIsOn(true);
      generatePDF();
    }
  };

  const generatePDF = async () => {
    if (contentRef.current) {
      const content = contentRef.current;
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfSrc(pdfUrl);
      setIsOn(true);
    }
  };
  return (
    <div className="flex">
      {/* Side Bar for All Orders */}
      <div className="w-3 flex flex-column border">
        {/* Filter and New Order button */}
        <div className="mt-3 flex gap-3">
          <Dropdown className="w-7">
            <Dropdown.Toggle
              variant=""
              className="w-100 flex fw-semibold justify-content-between text-lg outline-none border-none align-items-center"
            >
              All Purchase....
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item className="item">All</Dropdown.Item>
              <Dropdown.Item className="item">Draft</Dropdown.Item>
              <Dropdown.Item className="item">Issued</Dropdown.Item>
              <Dropdown.Item className="item">Received</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div>
            <Button variant="" className="bg-black text-white px-4">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              New
            </Button>
          </div>
        </div>
        {/* All Orders */}
        <div className="flex flex-column mt-3 cursor-pointer">
          <div
            className="flex flex-column align-items-between px-3 p-1 gap-2"
            style={{
              borderTop: "2px solid #DEE2E6",
              borderBottom: "2px solid #DEE2E6",
            }}
            onClick={() => {
              setDraft(false);
            }}
          >
            <div>
              <input type="checkbox" />
            </div>
            <div className="flex justify-content-between">
              <div className="w-8">
                <span className="fw-semibold">R.K. Retail Store</span>
                <div
                  className="text-sm flex justify-content-between"
                  style={{ color: "#9E9E9E" }}
                >
                  <span>PO - 00002</span>
                  <span>05/04/2024</span>
                </div>
              </div>
              <div>₹ 16,000</div>
            </div>
            <div className="text-sm fw-semibold" style={{ color: "#1E88E5" }}>
              ISSUED
            </div>
          </div>
          <div
            className="flex flex-column align-items-between px-3 p-1 gap-2"
            style={{
              borderTop: "2px solid #DEE2E6",
              borderBottom: "2px solid #DEE2E6",
            }}
            onClick={() => {
              setDraft(true);
            }}
          >
            <div>
              <input type="checkbox" />
            </div>
            <div className="flex justify-content-between">
              <div className="w-8">
                <span className="fw-semibold">Bellavita</span>
                <div
                  className="text-sm flex justify-content-between"
                  style={{ color: "#9E9E9E" }}
                >
                  <span>PO - 00003</span>
                  <span>07/04/2024</span>
                </div>
              </div>
              <div>₹ 4,000</div>
            </div>
            <div className="text-sm fw-semibold" style={{ color: "#BDBDBD" }}>
              DRAFT
            </div>
          </div>
          <div
            className="flex flex-column align-items-between px-3 p-1 gap-2"
            style={{
              borderTop: "2px solid #DEE2E6",
              borderBottom: "2px solid #DEE2E6",
            }}
          >
            <div>
              <input type="checkbox" />
            </div>
            <div className="flex justify-content-between">
              <div className="w-8">
                <span className="fw-semibold">24 Mantra Organic</span>
                <div
                  className="text-sm flex justify-content-between"
                  style={{ color: "#9E9E9E" }}
                >
                  <span>PO - 00004</span>
                  <span></span>
                </div>
              </div>
              <div>₹ 4,000</div>
            </div>
            <div className="text-sm fw-semibold" style={{ color: "#00C853" }}>
              RECEIVED
            </div>
          </div>
        </div>
      </div>
      {/* Selected Order */}
      <div className="w-9 flex flex-column gap-4 p-3">
        <div className="flex justify-content-between align-items-center">
          <h4>PO - 0006</h4>
          <CloseButton
            className="outline-none rounded-3"
            onClick={() => {
              navigate(ROUTES.PURCHASEORDERS);
            }}
          />
        </div>
        {/* Edit,Send mail,Download,Convert to Bill, and Delete Button */}
        <div className="flex justify-content-between">
          <div className="w-7 flex gap-3">
            <Button
              variant=""
              className="border button-hover flex gap-2 align-items-center rounded-3 px-2"
            >
              <FontAwesomeIcon icon={faPen} size="sm" />
              Edit
            </Button>
            <Button
              variant=""
              className="border button-hover flex gap-2 align-items-center rounded-3 px-2"
            >
              <FontAwesomeIcon icon={faEnvelope} size="sm" />
              Send Mail
            </Button>
            <Button
              variant=""
              className="border button-hover flex gap-2 align-items-center rounded-3 px-2"
            >
              <FontAwesomeIcon icon={faFilePdf} size="sm" />
              Download
            </Button>
            {Draft === false && (
              <Button
                variant=""
                className="border button-hover flex gap-2 align-items-center rounded-3 px-2"
              >
                <FontAwesomeIcon icon={faReceipt} size="sm" />
                Convert to Bill
              </Button>
            )}
          </div>
          <div className="flex gap-3">
            {Draft === true && (
              <Button
                variant=""
                className="rounded-3 px-2 text-white"
                style={{ backgroundColor: "#424242" }}
                onClick={() => {
                  setPopup(true);
                }}
              >
                Send Purchase Order
              </Button>
            )}
            <Button
              variant=""
              className="border-danger text-danger flex gap-2 align-items-center rounded-3 px-2"
            >
              <FontAwesomeIcon icon={faTrash} size="sm" />
              DELETE
            </Button>
          </div>
        </div>
        {!isOn ? (
          <div className="flex flex-column gap-4">
            {/* Order Details & Show PDF Toggle*/}
            <div className="flex justify-content-between align-items-center">
              <div className="flex flex-column gaap-3">
                <h4>PURCHASE ORDER</h4>
                <span className="text-sm">
                  Purchase Order <span className="fw-semibold">#PO-0006</span>
                </span>
              </div>
              <div className="flex gap-3">
                <span className="text-sm">Show PDF View</span>
                <div
                  className={`toggle-switch ${isOn ? "on" : "off"} bg-${
                    isOn && "black"
                  }`}
                  onClick={handleToggle}
                >
                  <div className="toggle-handle" />
                </div>
              </div>
            </div>
            {/* reference_no,Delivery_date,order_date */}
            <div
              className="w-4 p-2 text-sm flex flex-column gap-3"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="flex justify-content-between ">
                <span>REFERENCE NO.</span>
                <span className="w-5">127862</span>
              </div>
              <div className="flex justify-content-between">
                <span>DELIVERY DATE</span>
                <span className="w-5">05/04/2024</span>
              </div>
              <div className="flex justify-content-between">
                <span>ORDER DATE</span>
                <span className="w-5">05/04/2024</span>
              </div>
            </div>
            {/* Vendor Details & Product Details*/}
            <div className="flex flex-column gap-1">
              <div>
                <h6 className="fw-semibold">Vendor Details</h6>
                <Details />
              </div>
              <div>
                <h6 className="fw-semibold">Product Details</h6>
                <ProductTable size={1} />
              </div>
            </div>
            {/* Payment Terms/Status and Order Status */}
            <div className="flex gap-3 text-sm">
              <div className="flex w-3 flex-column gap-2">
                <h6 className="fw-semibold">Payment Terms</h6>
                <input
                  type="text"
                  readOnly
                  value={"Net 30"}
                  className="p-2 px-3 border outline-none rounded-3"
                />
              </div>
              <div className="flex w-3 flex-column gap-2">
                <h6 className="fw-semibold">Payment Status</h6>
                <input
                  type="text"
                  readOnly
                  value={"PENDING"}
                  className="p-2 px-3 border text-center fw-semibold text-danger outline-none rounded-3"
                />
              </div>
              <div className="flex w-3 flex-column gap-2">
                <h6 className="fw-semibold">Order Status</h6>
                <input
                  type="text"
                  readOnly
                  value={"ISSUED"}
                  className="p-2 px-3 text-center text-primary fw-semibold border outline-none rounded-3"
                />
              </div>
            </div>
            {/* NOTE and All calculation */}
            <div className="flex justify-content-between">
              <div className="text-sm w-5" style={{ color: "#53545C" }}>
                <span className="fw-semibold">Note:</span> All products ordered
                must meet the specified specifications and quality requirements
                as detailed in purchase order.
              </div>
              <div
                className="w-50 flex flex-column gap-3 pr-7"
                style={{ fontWeight: "400", fontSize: "17px" }}
              >
                <div className="w-100 flex justify-content-between">
                  <span>Sub Total</span>
                  <span>₹ 16,000.00</span>
                </div>
                <div className="w-100 flex justify-content-between align-items-center">
                  <span className="w-2">Discount</span>
                  <input
                    type="number"
                    className="w-4 border-none border border-2 rounded-3 outline-none p-1 px-2"
                  />
                  <span>0.00</span>
                </div>
                <div className="w-100 flex justify-content-between align-items-center">
                  <span className="w-2">Adjustments</span>
                  <input
                    type="number"
                    className="w-4 border-none border border-2 rounded-3 outline-none p-1 px-2"
                  />
                  <span>0.00</span>
                </div>
                <div
                  className="fw-semibold py-2 flex justify-content-between"
                  style={{
                    borderTop: "2px solid #D8D8D8",
                    borderBottom: "2px solid #D8D8D8",
                  }}
                >
                  <span>Total</span>
                  <span>₹ 16,000.00</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div ref={contentRef} className="flex flex-column gap-4">
            {/* EDIT_PDF and Show_PDF_Toggle Button */}
            <div className="flex justify-content-between align-items-center">
              <Button
                variant=""
                className="border text-white bg-black px-3 flex gap-2 align-items-center rounded-3 px-2"
              >
                <FontAwesomeIcon icon={faPen} size="sm" />
                Edit PDF
              </Button>
              <div className="flex gap-3">
                <span className="text-sm">Show PDF View</span>
                <div
                  className={`toggle-switch ${isOn ? "on" : "off"} bg-${
                    isOn && "black"
                  }`}
                  onClick={handleToggle}
                >
                  <div className="toggle-handle" />
                </div>
              </div>
            </div>
            {/* PDF page Content */}
            <div className="flex flex-column">
              <div className="flex flex-row-reverse text-sm">
                <div
                  className="text-center text-white w-2 p-2 fw-semibold"
                  style={{ backgroundColor: "#1E88E5" }}
                >
                  ISSUED
                </div>
              </div>
              <div
                className="invoice rounded-3 p-4 mb-3 shadow"
                style={{ backgroundColor: "#fff" }}
              >
                {/* vendor Info and Total amount */}
                <div className="flex justify-content-between">
                  <div className="flex gap-4 align-items-center">
                    <Image src={"#"} height="150px" width="150px"></Image>
                    <div className="text-sm">
                      <h5 className="fw-semibold">Sisyphus</h5>
                      <div
                        className="flex flex-column gap-1 mt-2"
                        style={{ color: "#667085" }}
                      >
                        <span>John Brandon</span>
                        <span>789/1 Sector-2C, 38200 Gandhinagar, France</span>
                        <span>848171214 | contact@betao.se</span>
                        <span className="fw-semibold">GST: 842-484021</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-column justify-content-between">
                    <span
                      className="rounded-3 w-100 p-2 px-3 text-sm fw-semibold"
                      style={{ backgroundColor: "#F4F5F6" }}
                    >
                      Purchase Order #PO-0001
                    </span>
                    <div className="text-right">
                      <h6 className="text-sm" style={{ color: "#667085" }}>
                        Total Amount
                      </h6>
                      <h4 className="fw-semibold">₹3,030.00</h4>
                    </div>
                  </div>
                </div>
                <div className="border p-4 border-2 mt-5 rounded-3">
                  <div className="flex gap-4">
                    {/* Bill date, Payment_deadline,Terms_of_Payment,Delivery_date */}
                    <div
                      className="rounded-3 p-3 flex flex-column w-6 gap-3"
                      style={{ backgroundColor: "#FAFAFA" }}
                    >
                      <div className="flex text-sm flex-column gap-1">
                        <span style={{ color: "#667085" }}>Bill date</span>
                        <span className="fw-semibold">03/05/2020</span>
                      </div>
                      <div className="flex text-sm flex-column gap-1">
                        <span style={{ color: "#667085" }}>Delivery Date</span>
                        <span className="fw-semibold">03/05/2020</span>
                      </div>
                      <div className="flex text-sm flex-column gap-1">
                        <span style={{ color: "#667085" }}>
                          Terms of Payment
                        </span>
                        <span className="fw-semibold">Within 15 days</span>
                      </div>
                      <div className="flex text-sm flex-column gap-1">
                        <span style={{ color: "#667085" }}>
                          Payment Deadline
                        </span>
                        <span className="fw-semibold">05/18/2020</span>
                      </div>
                    </div>
                    {/* Billing-Address & Note*/}
                    <div className="flex flex-column justify-content-around">
                      <div className="flex text-sm flex-column gap-2">
                        <span style={{ color: "#667085" }}>
                          Billing Address
                        </span>
                        <h5>Willy Wonka</h5>
                        <span className="w-8 " style={{ color: "#667085" }}>
                          1445 West Norwood Avenue, Itasca, Illinois, USA
                          97223041054 | om@om.com
                        </span>
                      </div>
                      <div>
                        <span style={{ color: "#667085" }}>Note</span>
                        <p className="text-sm">
                          This is a custom message that might be relevant to the
                          customer. It can span up to three or four rows. It can
                          span up to three or four rows.It can span up to three
                          or four rows.
                        </p>
                      </div>
                    </div>
                  </div>
                  <table className="table transparent-table mt-4">
                    <thead
                      className="border-top"
                      style={{ backgroundColor: "#FAFAFA" }}
                    >
                      <tr>
                        <th style={{ color: "#667085" }}>No.</th>
                        <th style={{ color: "#667085" }}>Article</th>
                        <th style={{ color: "#667085" }}>Quantity</th>
                        <th style={{ color: "#667085" }}>Unit Price</th>
                        <th style={{ color: "#667085" }}>GST</th>
                        <th style={{ color: "#667085" }}>Amount</th>
                        <th style={{ color: "#667085" }}>Final Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>
                          <div className="flex flex-column gap-1">
                            Product Name
                            <span style={{ color: "#667085" }}>
                              Product Description
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-column gap-1">
                            150
                            <span style={{ color: "#667085" }}>Unit(s)</span>
                          </div>
                        </td>
                        <td>
                          <b>₹</b>20.00
                        </td>
                        <td>0%</td>
                        <td>
                          <b>₹</b>3,000
                        </td>
                        <td>
                          <b>₹</b>3,000
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <div className="flex flex-column gap-1">
                            Product Name
                            <span style={{ color: "#667085" }}>
                              Product Description
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-column gap-1">
                            150
                            <span style={{ color: "#667085" }}>Unit(s)</span>
                          </div>
                        </td>
                        <td>
                          <b>₹</b>20.00
                        </td>
                        <td>0%</td>
                        <td>
                          <b>₹</b>3,000
                        </td>
                        <td>
                          <b>₹</b>3,000
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <div className="flex flex-column gap-1">
                            Product Name
                            <span style={{ color: "#667085" }}>
                              Product Description
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="flex flex-column gap-1">
                            150
                            <span style={{ color: "#667085" }}>Unit(s)</span>
                          </div>
                        </td>
                        <td>
                          <b>₹</b>20.00
                        </td>
                        <td>0%</td>
                        <td>
                          <b>₹</b>3,000
                        </td>
                        <td>
                          <b>₹</b>3,000
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="flex flex-column mb-2 align-items-end gap-3">
                    <div className="flex w-4 justify-content-between">
                      <span style={{color:"#667085"}}>Total HT</span>
                      <span>₹6,000</span>
                    </div>
                    <div className="flex w-4 justify-content-between">
                      <span style={{color:"#667085"}}>Total GST</span>
                      <span>₹0</span>
                    </div>
                    <div className="border w-4"></div>
                    <div className="flex w-4 fw-semibold justify-content-between">
                      <span>Total Price</span>
                      <span>₹6,000</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Send Purchase Order Confirm Popup */}
      <Dialog open={Popup} PaperProps={{ style: { width: "50%" } }}>
        <DialogContent className="flex justify-content-center">
          <div className="flex gap-2">
            <div className="w-7 flex flex-column justify-content-center gap-5">
              <div className="text-lg">
                Once you send the purchase order, it will be automatically
                updated to Open.
              </div>
              <div className="flex gap-3">
                <button
                  className="border border-black p-1 text-center w-4 text-white"
                  onClick={() => {
                    navigate(ROUTES.SENDMAIL);
                  }}
                  style={{ backgroundColor: "#313131" }}
                >
                  OK
                </button>
                <button
                  className="border border-black p-1 text-center w-4 bg-transparent"
                  onClick={() => {
                    setPopup(false);
                  }}
                >
                  CANCLE
                </button>
              </div>
            </div>
            <div>
              <Image
                src={images.Ballon}
                height="200"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditPurchaseOrder;
