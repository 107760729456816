import { useState, useMemo, useEffect,useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseButton } from "react-bootstrap";

import Dropdown1 from "../../components/Dropdown1/Dropdown1";
import {
  addAdjustment,
  getSingleAdjustments,
  updateAdjustment,
} from "../../controller/AdjustmentsController";
import Button from "../../components/Button";
import InventoryTable from "../../components/Invetorytable/Invetorytable";

import { ROUTES } from "../../navigators/Routes";

import { toast } from "../../utils/helper";

const AddAdjustments: React.FC = () => {
  const [selectedRadio, setSelectedRadio] = useState<boolean>(true);
  const [referenceNumber, setReferenceNumber] = useState<string>("");
  const [date, setDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [reason, setReason] = useState<string>("Select a reason");
  const [description, setDescription] = useState<string>("");
  const [items, setItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const handleRadioChange = () => {
    setSelectedRadio(!selectedRadio);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const isEditMode = useMemo(() => (state ? state.value : false), [state]);

  const getData = useCallback(async () => {
    try {
      const response = await getSingleAdjustments(state.id);
      const data = response.data;
      const transformedItems = data.items.map((item: any) => ({
        ...item,
        productId: item.product.id, // Extract productId
        product: undefined, // Remove product object
      }));
  
      setSelectedRadio(data.modeOfAdjustment === "1");
      setReferenceNumber(data.referenceNumber);
      setDate(data.date.split("T")[0]);
      setReason(data.reason);
      setDescription(data.description);
      setItems(transformedItems);
    } catch (error) {
      console.error("Error fetching adjustment data:", error);
      toast.error("Failed to load adjustment data.");
    }
  }, [state.id]); // Add dependencies (state.id)
  
  useEffect(() => {
    if (isEditMode) {
      getData();
    }
  }, [isEditMode, getData]);

  const handleGoBack = () => {
    navigate(ROUTES.ADJUSTMENTS, {
      replace: true,
    });
  };

  const handleSave = async (status: string) => {
    // Enhanced Validation
    if (!referenceNumber && !isEditMode) {
      toast.error("Reference Number is required.");
      return;
    }
    if (!reason || reason === "Select a reason") {
      toast.error("Please select a valid reason.");
      return;
    }
    if (items.length === 0) {
      toast.error("At least one item should be added.");
      return;
    }

    setLoading(true);

    // Prepare data to send
    const dataToSend = {
      modeOfAdjustment: selectedRadio ? "1" : "2",
      ...(isEditMode ? {} : { referenceNumber }),
      date,
      reason,
      status,
      description,
      items: items.map((item: any) => ({
        id: item.id, // Include if updating existing items
        productId: item.productId, // Ensure productId is sent
        quantityAvailable: item.quantityAvailable,
        newQuantityOnHand: item.newQuantityOnHand,
        quantityAdjusted: item.quantityAdjusted,
        originalValue: item.originalValue,
        newValue: item.newValue,
        valueAdjusted: item.valueAdjusted,
      })),
    };

    console.log("Payload before sending:", dataToSend); // Debugging

    try {
      const response = await (isEditMode
        ? updateAdjustment(dataToSend, state.id)
        : addAdjustment(dataToSend));

      if (response.message) {
        toast.success(response.message);
        handleGoBack();
      } else {
        toast.error(response.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Error saving adjustment:", error);
      toast.error("An error occurred while saving the adjustment.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center">
        <h2>{isEditMode ? "Edit Adjustment" : "New Adjustments"}</h2>
        <CloseButton onClick={handleGoBack} />
      </div>

      <div className="border rounded-2">
        <div className="flex gap-3 p-4">
          <div className="flex flex-column gap-4 w-25">
            <h6>Mode of Adjustment</h6>
            <h6>Reference Number</h6>
            <h6>Date</h6>
            <h6>Reason</h6>
            <h6>Description</h6>
          </div>
          <div className="flex flex-column gap-3 w-100">
            <div className="flex gap-5">
              <div className="flex gap-3 p-1 px-2 rounded-pill border">
                <input
                  type="radio"
                  id="QuantityAdjustment"
                  name="Adjustment"
                  checked={selectedRadio}
                  onChange={handleRadioChange}
                />
                Quantity Adjustment
              </div>
              <div className="flex gap-3 p-1 px-2 rounded-pill border">
                <input
                  type="radio"
                  id="ValueAdjustment"
                  name="Adjustment"
                  checked={!selectedRadio}
                  onChange={handleRadioChange}
                />
                Value Adjustment
              </div>
            </div>
            {!isEditMode && (
              <div className="flex gap-5">
                <input
                  type="text"
                  className="w-4 p-1 px-3 rounded-3 outline-none border-none border"
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </div>
            )}
            <div className="flex gap-5">
              <input
                type="date"
                value={date}
                readOnly
                className="w-4 p-1 px-3 rounded-3 outline-none border-none border"
              />
            </div>
            <div className="flex gap-5 w-4">
              <Dropdown1
                title={reason}
                titles={[
                  "Stolen Goods",
                  "Damaged Goods",
                  "Stock Written Off",
                  "Stocktaking Results",
                  "Inventory Revaluation",
                ]}
                placeholdertext="Add Custom Reason"
                onSelect={(selectedReason) => setReason(selectedReason)}
              />
            </div>
            <div className="flex gap-5">
              <textarea
                placeholder="Max 500 characters"
                className="w-4 p-2 px-3 rounded-3 outline-none border-none border"
                rows={5}
                cols={50}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <InventoryTable
          selectedRadio1={selectedRadio}
          items={items}
          setItems={setItems}
        />

        <div className="flex mt-5 mb-2 justify-content-around w-5">
          <Button
            variant=""
            className="flex gap-3 px-3 align-items-center bg-white p-2 outline-none border border-2 border-black rounded-3"
            onClick={() => handleSave("1")}
            isLoading={loading}
          >
            Save as draft
          </Button>
          <Button
            variant=""
            className="flex gap-3 px-3 align-items-center bg-black text-white p-2 outline-none border-2 rounded-3"
            onClick={() => handleSave("2")} // Convert to Adjusted
            isLoading={loading}
          >
            Convert to Adjusted
          </Button>
          <button
            className="flex gap-3 px-3 align-items-center bg-white p-2 outline-none border-2 rounded-3"
            onClick={handleGoBack}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAdjustments;
