import axios from "axios";
import ENV from "../env/env.config";
// import {getAccessToken} from './AuthService';
import { getToken } from "./AuthService";
// import { END_POINTS } from "../utils/ApiEndpoints";
import { encryptData, decryptData } from "../encDec/encDec";

class ApiService {
  getApiUrl() {
    return ENV.BASE_API_URL;
  }

  async callPostApi(url: string, parameters: any, headers: any = {}) {
    try {
      const accessToken = await getToken();

      headers = {
        "Content-Type": "application/json",
        Authorization: accessToken ? "Bearer " + accessToken : "",
        ...headers,
      };

      if (parameters instanceof FormData) {
      } else {
        // If not FormData, encrypt the data
        parameters = encryptData(parameters); // Adjust Content-Type for JSON
      }

      const response = await axios.post(url, parameters, {
        headers: headers,
      });

      const decryptedData = decryptData(response.data.data);

      return decryptedData;
    } catch (error: any) {
      if (error.response) {
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.status);

        // Return or handle the server's response as needed
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received for the request");
        return { message: "No response received for the request" };
      } else {
        // Something else happened in setting up the request
        console.log("Error setting up the request:", error.message);
        return { message: error.message };
      }
    }
  }

  async callGetApi(url: string, headers: any = {}) {
    try {
      const accessToken = await getToken();

      headers = {
        ...headers,
        "Content-Type": "application/json",
        //Authorization: 'Basic ' + base64.encode(USERNAME + ':' + PASSWORD),
        Authorization: accessToken ? "Bearer " + accessToken : "",
      };
      const response = await axios.get(url, { headers: headers });
      const decryptedData = decryptData(response.data.data);

      return decryptedData;
    } catch (error: any) {
      if (error.response) {
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.status);

        // Return or handle the server's response as needed
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received for the request");
        return { message: "No response received for the request" };
      } else {
        // Something else happened in setting up the request
        console.log("Error setting up the request:", error.message);
        return { message: error.message };
      }
    }
  }

  async callPutApi(url: string, data?: any, headers: any = {}) {
    try {
      const accessToken = await getToken();

      headers = {
        "Content-Type": "application/json",
        ...headers,
        //Authorization: 'Basic ' + base64.encode(USERNAME + ':' + PASSWORD),
        Authorization: accessToken ? "Bearer " + accessToken : "",
      };

      if (data instanceof FormData) {
      } else {
        // If not FormData, encrypt the data
        data = encryptData(data); // Adjust Content-Type for JSON
      }

      const response = await axios.put(url, data, { headers: headers });

      const decryptedData = decryptData(response.data.data);

      return decryptedData;
    } catch (error: any) {
      if (error.response) {
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.status);

        // Return or handle the server's response as needed
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received for the request");
        return { message: "No response received for the request" };
      } else {
        // Something else happened in setting up the request
        console.log("Error setting up the request:", error.message);
        return { message: error.message };
      }
    }
  }

  async callDeleteApi(url: string, headers: any = {}) {
    try {
      const accessToken = await getToken();
      headers = {
        "Content-Type": "application/json",
        ...headers,
        //Authorization: 'Basic ' + base64.encode(USERNAME + ':' + PASSWORD),
        Authorization: accessToken ? "Bearer " + accessToken : "",
      };
      const response = await axios.delete(url, { headers: headers });

      const decryptedData = decryptData(response.data.data);

      return decryptedData;
    } catch (error: any) {
      if (error.response) {
        console.log("Error data:", error.response.data);
        console.log("Error status:", error.response.status);

        // Return or handle the server's response as needed
        return error.response.data;
      } else if (error.request) {
        // The request was made but no response was received
        console.log("No response received for the request");
        return { message: "No response received for the request" };
      } else {
        // Something else happened in setting up the request
        console.log("Error setting up the request:", error.message);
        return { message: error.message };
      }
    }
  }
}

const apiService = new ApiService();

export default apiService;
