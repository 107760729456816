import { Image } from "react-bootstrap";
import { images } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";

const VendorProducts = () => {
	const navigate = useNavigate();
	return (
		<div className="flex mt-3 flex-column gap-3">
			<div className="flex mb-3 justify-content-between">
				<h5 className="fw-bold">Total Product</h5>
				<span
					className="flex gap-1 align-items-center text-sm fw-semibold"
					style={{ color: "#005D58" }}
					onClick={() => {
						navigate(ROUTES.VENDORDETAILSADDPRODUCT);
					}}
				>
					<FontAwesomeIcon icon={faPlus} /> Add Product
				</span>
			</div>
			<div
				className="flex rounded-4 p-1 px-2 justify-content-between align-items-center"
				style={{ backgroundColor: "#FBFCFF" }}
			>
				{/* Index,Image and ProductName and SubProduct name */}
				<div className="flex align-items-center gap-3">
					<Image
						// className={item.productSubCategory.length > 0 ? "ml-5" : "ml-6"}
						src={images.Rice}
						rounded
						width={"80px"}
						height={"80px"}
						style={{
							objectFit: "contain",
						}}
					/>
					<div className="flex flex-column justify-content-between">
						<span
							style={{ fontSize: "12.69px", color: "#7E7E7E", height: "40px" }}
						>
							24 Mantra Organic
						</span>
						<h3 className="fw-semibold text-xl">
							Organic Sonamasuri Brown Rice
						</h3>
					</div>
				</div>

				{/* BrandName ,StockInHand, ReorderLevel, RetailPrice */}
				<table className="table table-borderless text-center w-50 transparent-table">
					<tbody>
						<tr style={{ fontSize: "12.69px" }}>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								STOCK IN HAND
							</td>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								REORDER POINT
							</td>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								PURCHASE PRICE
							</td>
						</tr>
						<tr className="fw-semibold">
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								59
							</td>
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								59
							</td>
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								{"₹ 132"}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				className="flex rounded-4 p-1 px-2 justify-content-between align-items-center"
				style={{ backgroundColor: "#FBFCFF" }}
			>
				{/* Index,Image and ProductName and SubProduct name */}
				<div className="flex align-items-center gap-3">
					<Image
						// className={item.productSubCategory.length > 0 ? "ml-5" : "ml-6"}
						src={images.Rice}
						rounded
						width={"80px"}
						height={"80px"}
						style={{
							objectFit: "contain",
						}}
					/>
					<div className="flex flex-column justify-content-between">
						<span
							style={{ fontSize: "12.69px", color: "#7E7E7E", height: "40px" }}
						>
							24 Mantra Organic
						</span>
						<h3 className="fw-semibold text-xl">
							Organic Sonamasuri Brown Rice
						</h3>
					</div>
				</div>

				{/* BrandName ,StockInHand, ReorderLevel, RetailPrice */}
				<table className="table table-borderless text-center w-50 transparent-table">
					<tbody>
						<tr style={{ fontSize: "12.69px" }}>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								STOCK IN HAND
							</td>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								REORDER POINT
							</td>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								PURCHASE PRICE
							</td>
						</tr>
						<tr className="fw-semibold">
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								59
							</td>
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								59
							</td>
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								{"₹ 132"}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div
				className="flex rounded-4 p-1 px-2 justify-content-between align-items-center"
				style={{ backgroundColor: "#FBFCFF" }}
			>
				{/* Index,Image and ProductName and SubProduct name */}
				<div className="flex align-items-center gap-3">
					<Image
						// className={item.productSubCategory.length > 0 ? "ml-5" : "ml-6"}
						src={images.Rice}
						rounded
						width={"80px"}
						height={"80px"}
						style={{
							objectFit: "contain",
						}}
					/>
					<div className="flex flex-column justify-content-between">
						<span
							style={{ fontSize: "12.69px", color: "#7E7E7E", height: "40px" }}
						>
							24 Mantra Organic
						</span>
						<h3 className="fw-semibold text-xl">
							Organic Sonamasuri Brown Rice
						</h3>
					</div>
				</div>

				{/* BrandName ,StockInHand, ReorderLevel, RetailPrice */}
				<table className="table table-borderless text-center w-50 transparent-table">
					<tbody>
						<tr style={{ fontSize: "12.69px" }}>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								STOCK IN HAND
							</td>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								REORDER POINT
							</td>
							<td style={{ color: "#8B8C89", fontWeight: "400" }}>
								PURCHASE PRICE
							</td>
						</tr>
						<tr className="fw-semibold">
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								59
							</td>
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								59
							</td>
							<td
								className="text-xl"
								style={{ color: "#000000", fontWeight: "600" }}
							>
								{"₹ 132"}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default VendorProducts;
