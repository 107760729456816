import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "../Button";
import './Button.css';

interface ActionPopupProps {
  visible: boolean;
  title: string;
  message?: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ActionPopup = (props: ActionPopupProps) => {
  const {
    visible,
    title,
    message,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
  } = props;
  return (
    <Dialog
      open={visible}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { width: "30%", padding: "1% 2%",textAlign:"center"}, // Change the width here
      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {message && (
        <DialogContent className="flex justify-content-center">
          <DialogContentText
            id="alert-dialog-description"
            className="text-black"
          >
            <div className="fw-bold">{message}</div>
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions className="d-flex text-sm justify-content-around">
        <Button
          onClick={onConfirm}
          className="btn custom-button w-25"
          variant=""
          autoFocus
        >
          {confirmText}
        </Button>
        <Button
          onClick={onCancel}
          className="btn custom-button w-25"
          variant=""
        >
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionPopup;
