import "./style.css"
const PurchaseDetails = () => {
  return (
    <div className="flex justify-content-between">
        <div className='flex rounded-3 fw-bold flex-column align-items-center justify-content-around box'>
            <span></span>
            <span style={{fontSize:"50px"}}>35</span>
            <span className='text-sm text-center mb-2'>All Purchase Order</span>
        </div>
        <div className='flex rounded-3 fw-bold flex-column align-items-center justify-content-around box'>
            <span></span>
            <span style={{fontSize:"50px"}}>10</span>
            <span className='text-sm text-center mb-2'>In Progress</span>
        </div>
        <div className='flex rounded-3 fw-bold flex-column align-items-center justify-content-around box'>
            <span></span>
            <span style={{fontSize:"50px"}}>23</span>
            <span className='text-sm text-center mb-2'>Delivered</span>
        </div>
        <div className='flex rounded-3 fw-bold flex-column align-items-center justify-content-around box'>
            <span></span>
            <span style={{fontSize:"50px"}}>02</span>
            <span className='text-sm text-center mb-2'>Cancel</span>
        </div>
    </div>
  )
}

export default PurchaseDetails