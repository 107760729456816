import { Modal } from "@mui/material";
import { Spinner } from "react-bootstrap";

type Props = {
	loading: boolean;
};

const ScreenLoader = (props: Props) => {
	const { loading } = props;
	if (!loading) {
		return null;
	}

	return (
		<Modal
			open={loading}
			className="d-flex justify-content-center align-items-center"
		>
			<Spinner variant="primary" />
		</Modal>
	);
};

export default ScreenLoader;
