// @ts-nocheck
import Basket from "./images/categories/Basket.svg";
import Boxes from "./images/categories/Boxes.svg";
import CheckList from "./images/brands/CheckList.svg";
import SingleProduct from "./images/brands/SingleProduct.svg";
import ReadyProduct from "./images/Products/ready-stock 1.svg";
import Outofstock from "./images/Products/boxes.svg"
import Stocks from "./images/Products/package1.svg"
import Edit from "./images/Products/edit.svg"
import Delete from "./images/Products/delete.svg"
import cancle from "./images/Invetorytable/x-mark 1.png"
import more from "./images/Invetorytable/more 1.png"
import contact from "./images/vendors/contact.png"
import money from "./images/vendors/money-currency.png"
import pending from "./images/vendors/pending.png"
import delivered from "./images/Transaction/delivered.svg"
import fastdelivery from "./images/Transaction/fast-delivery.svg"
import packagedelivered from "./images/Transaction/package-delivered.svg"
import demoimg from "./images/demoimg.svg"
import Truck from "./images/vendors/Truck.png"
import Ballon from "./images/vendors/Ballon.svg"

// Below images are only use for test purpose
import Rice from "./images/Brownrice 1.png"
import Brand from "./images/brand.png"

export const images = {
	Basket,
	Boxes,
	CheckList,
	SingleProduct,
	ReadyProduct,
	Outofstock,
	Stocks,
	Edit,
	Delete,
	cancle,
	more,
	contact,
	money,
	pending,
	Rice,
	Brand,
	delivered,
	fastdelivery,
	packagedelivered,
	demoimg,
	Truck,
	Ballon
};
