interface Object {
  backgroundcolor: string;
  img: string;
  title: String;
  value: any;
}
interface Props {
  Items: Array<Object>;
}

// backgroundcolor
// img
// title
// value
const TotalDetails = (props: Props) => {
  const items = props.Items;
  return (
    <div className="container-shadow flex justify-content-between px-5 py-3 m-auto">
      {items.map((item, i) => {
        return (
          <div key={i} className="flex justify-content-around w-100">
            <div className="d-flex">
              <div
                className="d-flex justify-content-center align-items-center rounded-circle"
                style={{
                  width: "5rem",
                  height: "5rem",
                  background: item.backgroundcolor,
                }}
              >
                <img src={item.img} alt="" />
              </div>

              <div className="ml-3">
                <p
                  className="m-0"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#ACACAC",
                  }}
                >
                  {item.title}
                </p>

                <h2
                  style={{
                    color: "#333333",
                  }}
                  className="fw-semibold"
                >
                  {item.value}
                </h2>
              </div>
            </div>
            {i < items.length - 1 && (
              <div
                className="ml-6 mr-4"
                style={{
                  border: "1px solid #F0F0F0",
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TotalDetails;
