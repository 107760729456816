import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Dashboard from "../Dashboard";
import { useMemo } from "react";

type Props = {};

const Main = (props: Props) => {
	const location = useLocation();

	const isRootPath = useMemo(() => {
		return location.pathname === "/";
	}, [location.pathname]);

	return (
		<div className="d-flex position-relative">
			<div
				// className=" sticky-top"
				style={{
					minHeight: "100vh",
				}}
			>
				<Sidebar />
			</div>
			<div
				style={{
					width: "85vw",
				}}
			>
				{isRootPath ? (
					/* Render main page UI */
					<Dashboard />
				) : (
					/* Render children routes */
					<Outlet />
				)}
			</div>
		</div>
	);
};

export default Main;
