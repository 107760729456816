import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ProductTable from "../../components/Vendo&ProductDetailsTable/ProductTable";

const SendMail = () => {
  const navigate = useNavigate();
  return (
    <div className="p-3 flex flex-column gap-3">
      <h4 className="fw-bold">New Purchase Order</h4>
      {/* Back Button */}
      <button
        className="p-2 bg-transparent border-none flex gap-2 align-items-center fw-semibold"
        style={{ color: "#000000" }}
        onClick={() => {
          navigate(ROUTES.NEWPURCHASEORDERS);
        }}
      >
        {" "}
        <FontAwesomeIcon icon={faChevronLeft} size="sm" /> Back
      </button>
      {/* SEND MAIL Details */}
      <div className="border rounded-3 flex flex-column gap-2">
        <div className="p-3 flex flex-column gap-4">
          <h5 className="fw-bold">Send Email</h5>
          {/* Subject */}
          <div
            className="flex flex-column gap-2 w-6"
            style={{ fontWeight: "400" }}
          >
            <span>Subject</span>
            <input
              type="text"
              className="p-1 px-2 border outline-none rounded-3 text-sm"
              readOnly
              value={
                "Purchase order from Arshad Qureshi Store. (#Purchase Order: PO - 002)"
              }
            />
          </div>
          {/* From & To & CC,BCC*/}
          <div className="flex gap-5">
            <div className="w-6 flex gap-3">
              <div
                className="flex flex-column gap-2 w-6"
                style={{ fontWeight: "400" }}
              >
                <span>From</span>
                <input
                  type="text"
                  className="p-1 px-2 border outline-none rounded-3 text-sm"
                  readOnly
                  value={"thisisarshadq@gmail.com"}
                />
              </div>
              <div
                className="flex flex-column gap-2 w-6"
                style={{ fontWeight: "400" }}
              >
                <span>To</span>
                <input
                  type="text"
                  className="p-1 px-2 border outline-none rounded-3 text-sm"
                  readOnly
                  value={"abcstore907@gmail.com"}
                />
              </div>
            </div>
            <div className="flex gap-3">
              {" "}
              <span>CC</span> <span>BCC</span>
            </div>
          </div>
        </div>
        <hr />
        {/* Mail Body */}
        <div className="flex flex-column gap-4 p-3">
          <div className="flex flex-column gap-4 text-sm">
            <span>Dear ABC Store,</span>
            <span>
              The purchase order (PO-00002) is attached with this email.
            </span>
            <span>An overview of the purchase order is available below: </span>
          </div>
          {/* Purchase Order No & Date & Amount */}
          <h5 className="fw-bold">Purchase Order No : PO-00002</h5>
          <div className="w-2 flex flex-column gap-3 text-sm border-top border-bottom py-3">
            <div className="flex justify-content-between">
              <span>Order Date</span>
              <span className="fw-semibold">05 April, 2024</span>
            </div>
            <div className="flex justify-content-between">
              <span>Amount</span>
              <span className="fw-semibold">₹ 16,000.00</span>
            </div>
          </div>
          {/* Product Details */}
          <h5 className="fw-bold">Product Details</h5>
          <ProductTable size={1} />
          {/* Payment Terms */}
          <div className="flex justify-content-between">
            <div className="w-50 flex flex-column justify-content-between">
              <div className="flex flex-column w-4 gap-2">
                <h5 className="fw-semibold">Payment Terms</h5>
                <input
                  type="text"
                  className="p-1 px-2 border outline-none rounded-3 text-sm"
                  readOnly
                  value={"Due On Receipt"}
                />
              </div>
              <div className="text-sm" style={{ color: "#53545C" }}>
                <span className="fw-semibold">Note:</span> All products ordered
                must meet the specified specifications and quality requirements
                as detailed in purchase order.
              </div>
            </div>
            <div className="w-50">
              <div
                className="flex flex-column gap-3 pr-5"
                style={{ fontWeight: "400", fontSize: "17px" }}
              >
                <div className="w-100 flex justify-content-between">
                  <span>Sub Total</span>
                  <span>₹ 16,000.00</span>
                </div>
                <div className="w-100 flex justify-content-between align-items-center">
                  <span className="w-2">Discount</span>
                  <div className="flex w-4">
                    <input
                      type="number"
                      className="w-9 border-none border rounded-3 outline-none p-1 px-2"
                    />
                    <div className="w-3 border bg-light text-center align-content-center fw-semibold rounded-3 text-sm">
                      %
                    </div>
                  </div>
                  <span>0.00</span>
                </div>
                <div className="w-100 flex justify-content-between align-items-center">
                  <span className="w-2">Adjustments</span>
                  <input
                    type="number"
                    className="w-4 border-none border rounded-3 outline-none p-1 px-2"
                  />
                  <span>0.00</span>
                </div>
                <div
                  className="fw-semibold py-2 flex justify-content-between"
                  style={{
                    borderTop: "2px solid #D8D8D8",
                    borderBottom: "2px solid #D8D8D8",
                  }}
                >
                  <span>Total</span>
                  <span>₹ 16,000.00</span>
                </div>
              </div>
            </div>
          </div>
          {/* Attach PDF */}
          <div
            className="flex justify-content-between px-3 mt-5 text-sm rounded-3 p-2"
            style={{ backgroundColor: "#EEEEEE" }}
          >
            <div className="flex align-items-center w-5 gap-3">
              <input type="checkbox" style={{ width: "4%", height: "400%" }} />
              <span>Attach Purchase Order PDF</span>
            </div>
            <div
              className="px-2 p-1 flex align-items-center text-sm gap-2 w-2"
              style={{
                color: "#5A5A5A",
                backgroundColor: "#FCFCFC",
                border: "1px dashed #CFCFCF",
              }}
            >
              <FontAwesomeIcon icon={faFilePdf} size="xl" />
              PO-00002
            </div>
          </div>
          {/* Save & Cancle Buttons */}
          <div className="flex mt-5 mb-3 gap-4 w-5">
            <button
              className="px-5 bg-black text-white p-2 outline-none border-2 rounded-3"
              onClick={() => {
                navigate(ROUTES.EDITPURCHASEORDER);
              }}
            >
              Send
            </button>
            <button className="px-5 bg-white p-2 outline-none border border-2 rounded-3">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMail;
