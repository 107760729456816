import CryptoJS from "crypto-js";

const secretKey =
  "79d770b76d9be25f55f5b2a5633fe2c40f138c0f20427ecf384e40bdc11904ae";
const iv = "86038e6ce981316cfbb63286d96756da";

export function encryptData(data) {
  try {
    // Check if the data is JSON-serializable
    JSON.stringify(data);

    // If no error, proceed with encryption
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      CryptoJS.enc.Hex.parse(secretKey),
      {
        iv: CryptoJS.enc.Hex.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return { data: encrypted.toString() };
  } catch (error) {
    // If data is not JSON, return it as-is without encryption
    console.warn("Data is not JSON, sending as-is:", data);
    return { data };
  }
}

export function decryptData(encryptedData) {
  const decrypted = CryptoJS.AES.decrypt(
    encryptedData,
    CryptoJS.enc.Hex.parse(secretKey),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedText);
}
