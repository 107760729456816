import { useState } from "react";
import { encryptData, decryptData } from "../../encDec/encDec";

const EncryptionDecryption = () => {
  const [inputData, setInputData] = useState("");
  const [result, setResult] = useState("");

  // Handle encryption button click
  const handleEncrypt = () => {
    const encrypted = encryptData(inputData);
    setResult(`Encrypted Data: ${encrypted.data}`);
  };

  // Handle decryption button click
  const handleDecrypt = () => {
    const decrypted = decryptData(inputData);
    if (decrypted) {
      setResult(`Decrypted Data: ${JSON.stringify(decrypted)}`);
    } else {
      setResult("Decryption failed. Please ensure the data is valid.");
    }
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Encryption & Decryption</h1>

      <div className="mb-3">
        <label htmlFor="dataInput" className="form-label">
          Enter Data:
        </label>
        <input
          type="text"
          id="dataInput"
          className="form-control"
          placeholder="Enter text or encrypted data"
          value={inputData}
          onChange={(e) => setInputData(e.target.value)}
        />
      </div>

      <div className="text-center">
        <button className="btn btn-primary me-3" onClick={handleEncrypt}>
          Encrypt
        </button>
        <button className="btn btn-success" onClick={handleDecrypt}>
          Decrypt
        </button>
      </div>

      {result && (
        <div className="mt-4">
          <h2 className="text-center">Result</h2>
          <div
            className="alert alert-info text-wrap overflow-auto"
            role="alert"
            style={{ wordBreak: "break-all", maxHeight: "200px" }}
          >
            {result}
          </div>
        </div>
      )}
    </div>
  );
};

export default EncryptionDecryption;
