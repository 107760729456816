import { useRouteError } from "react-router-dom";

type Props = {};

const Error = (props: Props) => {
	const error: any = useRouteError();
	console.log("Route error:", error);

	return (
		<div id="error-page">
			<h1>Oops!</h1>
			<p>Sorry, an unexpected error has occurred.</p>
			<p>
				<i>{error?.statusText || error?.message}</i>
			</p>
		</div>
	);
};

export default Error;
