import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";
// import { getUserId } from "../utils/helper";

const BASE_API_URL = ApiService.getApiUrl();

export const getCategories = async () => {
	// const userId = await getUserId();
	const URL = BASE_API_URL + END_POINTS.MERCHANT_CATEGORIES;

	return ApiService.callGetApi(URL);
};
export const getSubCategories = async () => {
	// const userId = await getUserId();
	const URL = BASE_API_URL + END_POINTS.GET_SUB_CATEGORIES;

	return ApiService.callGetApi(URL);
};

export const addCategory = (payload: any) => {
	const URL = BASE_API_URL + END_POINTS.ADD_CATEGORY;
	return ApiService.callPostApi(URL, payload);
};

export const addSubCategory = (payload: any) => {
	const URL = BASE_API_URL + END_POINTS.ADD_SUB_CATEGORY;
	return ApiService.callPostApi(URL, payload);
};

export const updateCategory = (
	payload: any,
	id: string
) => {
	const URL = BASE_API_URL + END_POINTS.UPDATE_CATEGORY + id;
	return ApiService.callPutApi(URL, payload);
};

export const updateSubCategory = (
	payload: any,
	id: string
) => {
	const URL = BASE_API_URL + END_POINTS.UPDATE_SUB_CATEGORY + id;
	return ApiService.callPutApi(URL, payload);
};

export const deleteCategory = (id: string) => {
	const URL = BASE_API_URL + END_POINTS.DELETE_CATEGORY + id;
	return ApiService.callDeleteApi(URL);
};

export const deleteSubCategory = (id: string) => {
	const URL = BASE_API_URL + END_POINTS.DELETE_SUB_CATEGORY + id;
	return ApiService.callDeleteApi(URL);
};
