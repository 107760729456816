import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faBank } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Modal from "../VendorModal/Modal";

const VendorGeneralInfo = () => {
  const [isOn, setIsOn] = useState(false);
  const [AddAddressModal, setAddAddressModal] = useState(false);
  const [EditAddressModal, setEditAddressModal] = useState(false);
  const [AddbankModal, setAddbankModal] = useState(false);
  const [AddContactModal, setAddContactModal] = useState(false);
  const [EditContactModal, setEditContactModal] = useState(false);
  const [EditContact, setEditContact] = useState(false);

  const handleclose = () => {
    setAddAddressModal(false);
    setEditAddressModal(false);
    setAddbankModal(false);
    setAddContactModal(false);
    setEditContactModal(false);
    setEditContact(false);
  };
  const handlesave = () => {};

  const handleToggle = () => {
    setIsOn(!isOn);
  };
  return (
    <div className="mt-3 flex gap-4">
      {/* Left side */}
      <div className="w-50 flex flex-column gap-4">
        {/* Address */}
        <div className="flex flex-column gap-3">
          {/* Title and Add button */}
          <div className="flex justify-content-between">
            <span className="fw-semibold text-lg">Address</span>
            <button
              className="bg-transparent border-none flex text-sm gap-2 align-items-center fw-semibold"
              style={{ color: "#005D58" }}
              onClick={() => {
                setAddAddressModal(true);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Address
            </button>
          </div>
          <div
            className="flex flex-column rounded-3 p-2 justify-content-between"
            style={{ border: "1px solid #E1E1E1", height: "100px" }}
          >
            <div className="flex justify-content-between">
              <span
                className="fw-semibold"
                style={{ color: "#6C757D", fontSize: "12px" }}
              >
                Billing Address
              </span>
              <FontAwesomeIcon
                icon={faPen}
                size="sm"
                onClick={() => {
                  setEditAddressModal(true);
                }}
              />
            </div>
            <textarea
              placeholder="Add brand name"
              className="outline-none border-none"
              style={{ fontSize: "15.86px" }}
              value={
                "14, Rajasthan Soceity, Nr Shahalam Gate, Shahalam, Ahmedabad, Gujarat - 380028."
              }
            ></textarea>
          </div>
        </div>
        {/* Other Details */}
        <div className="flex flex-column gap-3">
          <span className="fw-semibold text-lg">Other Details</span>
          <div className="flex justify-content-between">
            <div
              className="flex flex-column p-2 border rounded-3"
              style={{ width: "30%" }}
            >
              <span style={{ color: "#6C757D", fontSize: "12.69px" }}>
                Payment Terms
              </span>
              <span style={{ fontSize: "15.86px" }}>Pay On Receipt</span>
            </div>
            <div
              className="flex flex-column p-2 border rounded-3"
              style={{ width: "30%" }}
            >
              <span style={{ color: "#6C757D", fontSize: "12.69px" }}>
                Currency
              </span>
              <span style={{ fontSize: "15.86px" }}>INR</span>
            </div>
            <div
              className="flex flex-column p-2 border rounded-3"
              style={{ width: "30%" }}
            >
              <span style={{ color: "#6C757D", fontSize: "12.69px" }}>
                Portal Status
              </span>
              <div
                className="flex justify-content-between"
                style={{ fontSize: "15.86px" }}
              >
                Active{" "}
                <div
                  className={`toggle-switch ${isOn ? "on" : "off"}`}
                  onClick={handleToggle}
                >
                  <div className="toggle-handle" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Bank Account Details */}
        <div className="flex flex-column gap-3">
          <div className="flex justify-content-between">
            <span className="fw-semibold text-lg">Bank Account Details</span>
            <button
              className="bg-transparent border-none flex text-sm gap-2 align-items-center fw-semibold"
              style={{ color: "#005D58" }}
              onClick={() => {
                setAddbankModal(true);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Bank Account
            </button>
          </div>
          <div
            className="flex flex-column gap-3 border border-2 rounded-3 px-3 py-3"
            style={{ width: "60%", color: "#6C757D", fontSize: "12.69px" }}
          >
            {/* <div className="flex justify-content-end">
                  <FontAwesomeIcon icon={faPen} size="sm" />
                </div> */}
            <div className="flex justify-content-between gap-2">
              <span
                className="flex gap-1 align-items-center"
                style={{ width: "42.5%" }}
              >
                <FontAwesomeIcon icon={faBank} />
                Bank Name
              </span>
              <span style={{ width: "5%" }}>:</span>
              <span style={{ width: "42.5%" }}>-</span>
            </div>
            <div className="flex justify-content-between gap-2">
              <span
                className="flex gap-1 align-items-center"
                style={{ width: "42.5%" }}
              >
                Account Holder Name
              </span>
              <span style={{ width: "5%" }}>:</span>
              <span style={{ width: "42.5%" }}>-</span>
            </div>
            <div className="flex justify-content-between gap-2">
              <span
                className="flex gap-1 align-items-center"
                style={{ width: "42.5%" }}
              >
                Account Number
              </span>
              <span style={{ width: "5%" }}>:</span>
              <span style={{ width: "42.5%" }}>-</span>
            </div>
            <div className="flex justify-content-between gap-2">
              <span
                className="flex gap-1 align-items-center"
                style={{ width: "42.5%" }}
              >
                IFSC Code
              </span>
              <span style={{ width: "5%" }}>:</span>
              <span style={{ width: "42.5%" }}>-</span>
            </div>
          </div>
        </div>
      </div>
      {/* Right side */}
      <div className="w-50 flex flex-column gap-4">
        {/* Contact Person */}
        <div className="flex flex-column gap-3">
          {/* Title and Add button */}
          <div className="flex justify-content-between">
            <span className="fw-semibold text-lg">Contact Person</span>
            <button
              className="bg-transparent border-none flex text-sm gap-2 align-items-center fw-semibold"
              style={{ color: "#005D58" }}
              onClick={() => {
                setAddContactModal(true);
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faPlus} /> Add Contact
            </button>
          </div>
          {/* Details Container */}
          <div
            className="py-2 px-3 rounded-3 flex flex-column gap-3"
            style={{ backgroundColor: "#EEEEEE" }}
          >
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  Contact Name
                </span>
                <span className="text-lg fw-semibold">Mark Anthony</span>
              </div>
              <div>
                <button className="text-sm outline-none border-none bg-white rounded-3 px-2 py-1">
                  Primary
                </button>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  Department
                </span>
                <span>Sales Department</span>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  EMAIL
                </span>
                <span>themarkanthony@gmail.com</span>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  CONTACT
                </span>
                <span>+91 8132082190</span>
              </div>
            </div>
            <div className="flex justify-content-between mt-4">
              <FontAwesomeIcon
                icon={faPen}
                className="ml-2"
                onClick={() => {
                  setEditContactModal(true);
                }}
              />
              <button className="bg-black border-none rounded-3 py-1 px-2 text-sm text-white">
                Send Email
              </button>
            </div>
          </div>
        </div>
        {/* Other Contact Person */}
        <div className="flex flex-column gap-3">
          {/* Title */}
          <span className="fw-semibold text-lg">Other Contact Person</span>
          {/* Details Container */}
          <div
            className="py-2 px-3 rounded-3 flex flex-column gap-3"
            style={{ backgroundColor: "#EEEEEE" }}
          >
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  Contact Name
                </span>
                <span className="text-lg fw-semibold">Mark Anthony</span>
              </div>
              <div className="text-sm mt-2">
                <FontAwesomeIcon icon={faPen} onClick={()=>{setEditContact(true)}} />
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  EMAIL
                </span>
                <span>themarkanthony@gmail.com</span>
              </div>
            </div>
            <div className="flex justify-content-between">
              <div
                className="flex flex-column p-2 w-100 rounded-3"
                style={{ width: "30%" }}
              >
                <span
                  style={{
                    color: "#6C757D",
                    fontSize: "12.69px",
                    fontWeight: "600",
                  }}
                >
                  CONTACT
                </span>
                <span>+91 8132082190</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modals for Edit and Add */}
      {/* Add Address Modal */}
      <Modal
        title="Add Address"
        show={AddAddressModal}
        handleClose={handleclose}
        handleSave={handlesave}
        Savetext={"Save"}
        size="lg"
        body={
          <div
            className="flex flex-column rounded-3 w-100 p-2 justify-content-between"
            style={{ border: "1px solid #E1E1E1", height: "110px" }}
          >
            <span
              className="fw-semibold"
              style={{ color: "#6C757D", fontSize: "12px" }}
            >
              BILLING ADDRESS
            </span>
            <textarea
              name=""
              id=""
              className="outline-none border-none mb-2"
              placeholder="Add billing address of vendor"
            ></textarea>
          </div>
        }
      />
      {/* Edit Address Modal */}
      <Modal
        title="Edit Address"
        show={EditAddressModal}
        handleClose={handleclose}
        handleSave={handlesave}
        Savetext={"Save"}
        size="lg"
        body={
          <div
            className="flex flex-column rounded-3 w-100 p-2 justify-content-between"
            style={{ border: "1px solid #E1E1E1", height: "110px" }}
          >
            <span
              className="fw-semibold"
              style={{ color: "#6C757D", fontSize: "12px" }}
            >
              BILLING ADDRESS
            </span>
            <textarea
              name=""
              id=""
              className="outline-none border-none mb-2"
              placeholder="Add billing address of vendor"
            ></textarea>
          </div>
        }
      />
      {/* Add Bank Deatails */}
      <Modal
        title="Add Bank Details"
        show={AddbankModal}
        handleClose={handleclose}
        handleSave={handlesave}
        size="lg"
        Savetext={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Bank Name
                </span>
                <input
                  type="text"
                  placeholder="Add bank name"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Account Holder Name
                </span>
                <input
                  type="text"
                  placeholder="Add account holder name"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Account Number
                </span>
                <input
                  type="text"
                  placeholder="Add account number"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Confirm Account Number
                </span>
                <input
                  type="text"
                  placeholder="Add account number"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  IFSC Code
                </span>
                <input
                  type="text"
                  placeholder="Add IFSC Code"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
      {/* Add Contact Details */}
      <Modal
        title="Add Contact Details"
        show={AddContactModal}
        handleClose={handleclose}
        handleSave={handlesave}
        size="lg"
        Savetext={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Contact Name
                </span>
                <input
                  type="text"
                  placeholder="Add contact name"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Department
                </span>
                <input
                  type="text"
                  placeholder="Add department"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  E-Mail Address
                </span>
                <input
                  type="text"
                  placeholder="Add email address"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Contact Number
                </span>
                <input
                  type="text"
                  placeholder="Add contact number"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex rounded-3 w-50 p-2 align-items-center justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "50px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "15px" }}
                >
                  PRIMARY
                </span>
                <input type="checkbox" className="outline-none border-none" />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
      {/* Edit Contact Details */}
      <Modal
        title="Edit Contact Details"
        show={EditContactModal}
        handleClose={handleclose}
        handleSave={handlesave}
        size="lg"
        Savetext={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Contact Name
                </span>
                <input
                  type="text"
                  placeholder="Add contact name"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Department
                </span>
                <input
                  type="text"
                  placeholder="Add department"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  E-Mail Address
                </span>
                <input
                  type="text"
                  placeholder="Add email address"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Contact Number
                </span>
                <input
                  type="text"
                  placeholder="Add contact number"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex rounded-3 w-50 p-2 align-items-center justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "50px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "15px" }}
                >
                  PRIMARY
                </span>
                <input type="checkbox" className="outline-none border-none" />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
      {/* Edit Conatact */}
      <Modal
        title="Edit Contact"
        show={EditContact}
        handleClose={handleclose}
        handleSave={handlesave}
        size="lg"
        Savetext={"Save"}
        body={
          <div className="flex gap-4 flex-column">
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Contact Name
                </span>
                <input
                  type="text"
                  placeholder="Add contact name"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  E-Mail Address
                </span>
                <input
                  type="text"
                  placeholder="Add email address"
                  className="outline-none border-none mb-2"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
                style={{ border: "1px solid #E1E1E1", height: "100px" }}
              >
                <span
                  className="fw-semibold"
                  style={{ color: "#6C757D", fontSize: "12px" }}
                >
                  Contact Number
                </span>
                <input
                  type="text"
                  placeholder="Add contact number"
                  className="outline-none border-none mb-2"
                />
              </div>
              <div className="w-50"></div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default VendorGeneralInfo;
