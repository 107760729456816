import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";

const BASE_API_URL = ApiService.getApiUrl();

export const addAdjustment = (payload: any) => {
    const URL = BASE_API_URL + END_POINTS.ADD_ADJUSTMENT;
    return ApiService.callPostApi(URL, payload);
};

export const updateAdjustment = (payload:any,Id:any) => {
  const URL = BASE_API_URL + END_POINTS.UPDATE_ADJUSTMENTS +Id;
  return ApiService.callPutApi(URL,payload);
};

export const getAdjustments = async () => {
  const URL = BASE_API_URL + END_POINTS.GET_ADJUSTMENTS;
  return ApiService.callGetApi(URL);
};
export const getSingleAdjustments = async (id:any) => {
  const URL = BASE_API_URL + END_POINTS.GET_SINGLE_ADJUSTMENTS +id ;
  return ApiService.callGetApi(URL);
};

export const deleteAdjustment = (Id: any) => {
  const URL = BASE_API_URL + END_POINTS.DELETE_ADJUSTMENTS + Id;
  return ApiService.callDeleteApi(URL);
};