import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";

const BASE_API_URL = ApiService.getApiUrl();

export const uploadFile = async (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_FILE;
  return ApiService.callPostApi(URL, payload, {
    "Content-Type": "multipart/form-data",
  });
};
