import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Details from "../../components/Vendo&ProductDetailsTable/VendorDetailsTable";
import ProductTable from "../../components/Vendo&ProductDetailsTable/ProductTable";
import Dropdown1 from "../../components/Dropdown1/Dropdown1";
import { CloseButton, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {ROUTES} from "../../navigators/Routes"
import { images } from "../../assets";

const NewPurchaseOrder = () => {
  const navigate=useNavigate();
  return (
    <div className="p-3">
      <div className="flex justify-content-between align-items-center">
        <h4 className="mb-4 fw-bold">New Purchase Order</h4>
        <CloseButton onClick={()=>{navigate(ROUTES.PURCHASEORDERS)}} />
      </div>
      <div className="ml-3 border flex flex-column gap-3 border-2 rounded-3 py-3">
        {/* Purchase Order Id,reference Number, Date,Expected Delivery Date */}
        <div className="flex p-3 justify-content-between align-items-center">
          <div className="flex flex-column gap-3 w-50">
            <div className="flex justify-content-between align-items-center">
                <span className="text-lg w-50" style={{color:"#616161"}}>Purchase Order#*</span>
                <input type="text" className="border w-50 rounded-3 outline-none p-2 px-3 text-center" value={"PO - 0007"} />
            </div>
            <div className="flex justify-content-between align-items-center">
                <span className="text-lg w-50" style={{color:"#616161"}}>Reference Number</span>
                <input type="text" className="border w-50 rounded-3 outline-none p-2 px-3 text-center" value={"7281911"} />
            </div>
            <div className="flex justify-content-between align-items-center">
                <span className="text-lg w-50" style={{color:"#616161"}}>Date</span>
                <input type="text" className="border w-50 rounded-3 outline-none p-2 px-3 text-center" value={"30/07/2024"} />
            </div>
            <div className="flex justify-content-between align-items-center">
                <span className="text-lg w-50" style={{color:"#616161"}}>Expected Delivery Date</span>
                <input type="text" className="border w-50 rounded-3 outline-none p-2 px-3 text-center" value={"30/07/2024"} />
            </div>
          </div>
          <Image src={images.Truck}/>
        </div>
        {/* Vendor Details & Add New Vendor Button */}
        <div className="flex justify-content-between align-items-center mt-3 mb-3 px-3">
          <h5 className="fw-semibold">Vendor Details</h5>
          <div className="flex">
            <Button
              variant=""
              className="border text-sm fw-semibold border-2 px-4"
              style={{ color: "#53545C" }}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add New Vendor
            </Button>
          </div>
        </div>
        {/* Vendor Details Table */}
        <Details />
        <div>
          <h5 className="pl-3 fw-semibold">Product Details</h5>
        </div>
        {/* Product Table */}
        <ProductTable size={2} />
        {/* Add Row Button */}
        <div className="p-2 px-4 -mt-3">
          <button
            className="flex gap-3 align-items-center p-2 outline-none border rounded-3"
            style={{ backgroundColor: "#D7D7D7" }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size="sm"
              className="rounded-circle p-2 bg-primary"
            />
            Add New Row
          </button>
        </div>
        {/* Divisor */}
        <div style={{ borderBottom: "10px solid #EFEFEF" }}></div>
        {/* payment Terms */}
        <div className="flex flex-column gap-5 pl-3">
          <div>
            <h5 className="fw-semibold">Payment Terms</h5>
            <div className="w-3 mt-3">
              <Dropdown1
                title={"Due On Receipt"}
                titles={[
                  "Net 15",
                  "Net 30",
                  "Net 45",
                  "Net 60",
                  "Due end of the month",
                  "Due end of next month",
                  "Due on receipt",
                ]}
                placeholdertext=""
                onSelect={()=>{}}
              />
            </div>
          </div>
          {/* Note,Sub Total,Discount,Adjustments,Total */}
          <div className="flex ">
            {/* Note */}
            <div className="text-sm w-50" style={{ color: "#53545C" }}>
              <span className="fw-semibold">Note:</span> All products ordered
              must meet the specified specifications and quality requirements as
              detailed in purchase order.
            </div>
            {/* Sub Total,Total */}
            <div
              className="w-50 flex flex-column gap-3 pr-7"
              style={{ fontWeight: "400", fontSize: "17px" }}
            >
              <div className="w-100 flex justify-content-between">
                <span>Sub Total</span>
                <span>₹ 16,000.00</span>
              </div>
              <div className="w-100 flex justify-content-between align-items-center">
                <span className="w-2">Discount</span>
                <div className="flex w-4">
                  <input
                    type="number"
                    className="w-9 border-none border rounded-3 outline-none p-1 px-2"
                  />
                  <div className="w-3">
                    <Dropdown1
                      title={"%"}
                      titles={["%", "₹"]}
                      placeholdertext=""
                      onSelect={()=>{}}
                    />
                  </div>
                </div>
                <span>0.00</span>
              </div>
              <div className="w-100 flex justify-content-between align-items-center">
                <span className="w-2">Adjustments</span>
                <input
                  type="number"
                  className="w-4 border-none border rounded-3 outline-none p-1 px-2"
                />
                <span>0.00</span>
              </div>
              <div
                className="fw-semibold py-2 flex justify-content-between"
                style={{
                  borderTop: "2px solid #D8D8D8",
                  borderBottom: "2px solid #D8D8D8",
                }}
              >
                <span>Total</span>
                <span>₹ 16,000.00</span>
              </div>
            </div>
          </div>
          {/* Buttons Save as Draft, Save & Send,Cancle */}
          <div className="flex mt-5 mb-7 justify-content-around w-5">
            <button className=" px-3 bg-white p-2 outline-none border border-2 rounded-3">
              Save as draft
            </button>
            <button className="px-3 bg-black text-white p-2 outline-none border-2 rounded-3" onClick={()=>{navigate(ROUTES.SENDMAIL)}}>
              Save & Send
            </button>
            <button className="px-3 bg-white p-2 outline-none border border-2 rounded-3">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPurchaseOrder;
