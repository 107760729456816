import React from "react";
import ReactDOM from "react-dom/client";

/* 
  Prime React Configuration
 */
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css"; //flex

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Styles
import "./index.css";

// Components
import Navigator from "./navigators/Navigator";

// Import PrimeReactProvider
import { PrimeReactProvider } from "primereact/api";

// Firebase
import "./env/firebase.config";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<PrimeReactProvider>
			<Navigator />
			<ToastContainer />
		</PrimeReactProvider>
	</React.StrictMode>
);
