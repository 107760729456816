import { Image } from "react-bootstrap";
import { images } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowUpRightFromSquare,
	faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "react-bootstrap";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";
import VendorGeneralInfo from "../../components/VendorFullDetailsComponent/VendorGeneralInfo";
import VendorProducts from "../../components/VendorFullDetailsComponent/VendorProducts";
import VendorTransaction from "../../components/VendorFullDetailsComponent/VendorTransaction";

const VendorDetails = () => {
	const [activeTab, setActiveTab] = useState("General Information");

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.state) {
			setActiveTab("Product");
		}
	}, [location.state]);

	const tabs = ["General Information", "Product", "Transaction", "Mails"];
	return (
		<div className="p-3">
			<h4 className="mb-4 fw-bold">Brands (Vendor)</h4>
			<div
				className="mt-3 rounded-3 pl-5 p-3 flex flex-column gap-3"
				style={{ border: "1px solid #EEEEEE" }}
			>
				{/* Brand Vendor Card */}
				<div className="flex gap-4 ml-5 align-items-center">
					<Image
						src={images.Brand}
						height={99}
						width={99}
						className="rounded-5 border"
					/>
					<div
						className="flex flex-column justify-content-around"
						style={{ height: "79px" }}
					>
						<div className="flex gap-3">
							<span className="fw-semibold text-lg">24 Mantra Organic</span>
							<span className="bg-black text-sm text-white flex gap-1 align-items-center p-1 px-2 rounded-3">
								www.24mantra.com{" "}
								<FontAwesomeIcon icon={faArrowUpRightFromSquare} />{" "}
							</span>
						</div>
						<div className="text-sm flex gap-2">
							<span className="p-1 px-2 border rounded-3">
								Payables : <span className="fw-semibold">₹1000</span>
							</span>
							<span className="p-1 px-2 border rounded-3">
								Total Product : <span className="fw-semibold">40</span>
							</span>
						</div>
					</div>
				</div>
				{/* Back Button */}
				<button
					className="p-2 mt-3 bg-transparent border-none flex gap-2 align-items-center fw-semibold"
					style={{ color: "#005D58" }}
					onClick={() => {
						navigate(ROUTES.VENDOR);
					}}
				>
					{" "}
					<FontAwesomeIcon icon={faChevronLeft} /> Back
				</button>
				<Nav
					className="tabs w-50 justify-content-between fw-semibold"
					style={{ fontSize: "15.86px" }}
				>
					{tabs.map((tab) => (
						<NavItem key={tab}>
							<NavLink
								className={`tab ${activeTab === tab ? "active" : ""}`}
								onClick={() => setActiveTab(tab)}
							>
								{tab}
							</NavLink>
						</NavItem>
					))}
				</Nav>
				{/* Main Details */}
				{activeTab === "General Information" && <VendorGeneralInfo />}
				{activeTab === "Product" && <VendorProducts />}
				{activeTab === "Transaction" && <VendorTransaction />}
			</div>
		</div>
	);
};

export default VendorDetails;
