import { Image } from "react-bootstrap";
import { images } from "../../assets";
import "./style.css";
interface ViewCategoryProps {
  data: any;
  onEdit: (id:any) => void;
  onDelete: (id:any) => void;
}

const ViewProducts = (props: ViewCategoryProps) => {
  const { data,onEdit,onDelete } = props;
  return (
    <div className="flex flex-column gap-3">
      {data.map((item: any, i: any) => {
        return (
          <div
            key={i}
            className="flex border border-2 rounded-4 p-2 px-3 justify-content-between align-items-center"
            style={{ backgroundColor: "#FAFAFA" }}
          >
            {/* Index,Image and ProductName and SubProduct name */}
            <div
              className="flex align-items-center gap-4"
              style={{ width: "40%" }}
            >
              <div className="fw-bold">{i + 1}</div>
              <div className="flex align-items-center gap-3">
                <Image
                  // className={item.productSubCategory.length > 0 ? "ml-5" : "ml-6"}
                  src={item.image}
                  rounded
                  width={"80px"}
                  height={"80px"}
                  style={{
                    objectFit: "contain",
                  }}
                />
                <div>
                  <h3 className="fw-semibold text-xl">
                    {/* Organic Sonamasuri Brown Rice */}
                    {item.name}
                  </h3>
                  <div
                    className="flex gap-3 fw-normal"
                    style={{ color: "#8B8C89" }}
                  >
                    <span className="border border-2 rounded-pill px-2 text-sm">
                      {item.category.name}
                    </span>
                    <span className="border border-2 rounded-pill px-2 text-sm">
                      {item.subCategory.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* BrandName ,StockInHand, ReorderLevel, RetailPrice */}
            <table className="table table-borderless text-center w-50 transparent-table">
              <tbody>
                <tr>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Brand Name
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Stock In Hand
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Reorder Level
                  </td>
                  <td
                    className="text-sm"
                    style={{ color: "#8B8C89", fontWeight: "400" }}
                  >
                    Retail Price
                  </td>
                </tr>
                <tr className="fw-semibold">
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {item.brand.name}
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {item.totalQuantity}
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {item.reorderPoint}
                  </td>
                  <td
                    className="text-xl"
                    style={{ color: "#000000", fontWeight: "600" }}
                  >
                    {`₹ ${item.retailSalePrice}`}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Edit Delete Button */}
            <div className="d-flex gap-4">
              <Image
                src={images.Edit}
                height={25}
                width={25}
                onClick={() => {
                  onEdit(item.id);
                }}
              />
              <Image
                src={images.Delete}
                height={23}
                width={23}
                onClick={() => {
                  onDelete(item.id);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ViewProducts;
