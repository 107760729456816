import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints"

const BASE_API_URL = ApiService.getApiUrl();

export const addBrand = (payload: any) => {
	const URL = BASE_API_URL + END_POINTS.ADD_BRAND;
	return ApiService.callPostApi(URL, payload);
};

export const getBrands = async () => {
	const URL = BASE_API_URL + END_POINTS.GET_BRAND;
	return ApiService.callGetApi(URL);
};

export const updateBrand = (payload: any, id: string) => {
	const URL = BASE_API_URL + END_POINTS.UPDATE_BRAND + id;
	return ApiService.callPutApi(URL, payload);
};

export const deleteBrand = (id: string) => {
	const URL = BASE_API_URL + END_POINTS.DELETE_BRAND + id;
	return ApiService.callDeleteApi(URL);
};
