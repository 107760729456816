import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface HeaderProps {
	title: string;
	onButtonClick: () => void;
	Buttonname:String;
}

const Header = (props: HeaderProps) => {
	const { title, onButtonClick } = props;

	return (
		<div className="pt-3 pb-2 px-4 d-flex align-items-center justify-content-between">
			<div>
				<h4 style={{fontWeight:"600"}}>{title}</h4>
			</div>

			<div>
				<button className="btn btn-dark border-none" onClick={onButtonClick}>
					<FontAwesomeIcon icon={faPlus} size="sm" />
					<span>{" "}{props.Buttonname}</span>
				</button>
			</div>
		</div>
	);
};

export default Header;
