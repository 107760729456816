import ApiService from "../services/ApiService";
import { END_POINTS } from "../utils/ApiEndpoints";
// import { getUserId } from "../utils/helper";

const BASE_API_URL = ApiService.getApiUrl();

export const addProduct = (payload: any) => {
  const URL = BASE_API_URL + END_POINTS.ADD_PRODUCT;
  return ApiService.callPostApi(URL, payload);
};

export const updateProduct = (payload: any, productId: number) => {
  const URL = BASE_API_URL + END_POINTS.UPDATE_PRODUCT + productId;
  return ApiService.callPutApi(URL, payload);
};

export const getProducts = async () => {
  const URL = BASE_API_URL + END_POINTS.GET_PRODUCTS;
  return ApiService.callGetApi(URL);
};

export const getProductSummary = () => {
  const URL = BASE_API_URL + END_POINTS.GET_PRODUCT_SUMMARY;
  return ApiService.callGetApi(URL);
};

export const getProductById = (productId: number) => {
  const URL = BASE_API_URL + END_POINTS.GET_SINGLE_PRODUCT + productId;
  return ApiService.callGetApi(URL);
};

export const deleteProduct = (productId: any) => {
  const URL = BASE_API_URL + END_POINTS.DELETE_PRODUCT + productId;
  return ApiService.callDeleteApi(URL);
};
