import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import { SIDE_MENU_ITEMS } from "../../utils/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { logoutUser } from "../../services/AuthService";
import {
	getUserData,
	updateUserAuthenticationStatus,
} from "../../utils/helper";
import { ROUTES } from "../../navigators/Routes";

import "./Sidebar.css";

const Sidebar = () => {
	const navigate = useNavigate();

	const [collapsed] = useState(false);
	const [activeSubMenuId, setActiveSubMenuId] = useState("");

	const toggleCollapse = () => {
		// setCollapsed(!collapsed);
	};

	const toggleSubmenu = (id: string) => {
		setActiveSubMenuId(activeSubMenuId === id ? "" : id);
	};

	const onLogoutPress = async () => {
		try {
			const userData = await getUserData();
			console.log("userData :>> ", userData);

			if (!userData) {
				alert("User not found");
				return;
			}

			const response = await logoutUser({
				id: userData.id,
			});

			if (response.statusCode === 400) {
				alert(response.message);
				return;
			}

			await updateUserAuthenticationStatus(false, null);

			navigate(ROUTES.LOGIN, { replace: true });
		} catch (error) {
			console.log("Error in logging out", error);
		}
	};

	return (
		<div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
			<div className="sidebar-header gap-1" onClick={toggleCollapse}>
				<span style={{ fontSize: "20px", fontWeight: "900" }}>urbanlyfe</span>
				<div className="border h-75"></div>
				<span className="flex flex-column" style={{ fontSize: "13px" }}>
					For <span>Retail Store</span>
				</span>
			</div>
			<CSSTransition
				in={!collapsed}
				timeout={300}
				classNames="sidebar-menu"
				unmountOnExit
			>
				<ul className="sidebar-menu">
					{SIDE_MENU_ITEMS.map((item) => (
						<li className="sidebar-menu-item" key={item.id}>
							{item.subMenu.length > 0 ? (
								<div className="w-100">
									<div
										className="d-flex align-items-center justify-content-between"
										style={{ width: "100%" }}
										onClick={() => toggleSubmenu(item.id)}
									>
										<div>
											<FontAwesomeIcon icon={item.icon} />
											<span>{item.title}</span>
										</div>
										<FontAwesomeIcon
											icon={faChevronRight}
											size="sm"
											rotation={activeSubMenuId === item.id ? 90 : undefined}
										/>
									</div>
									{activeSubMenuId === item.id && (
										<CSSTransition
											in={activeSubMenuId === item.id}
											timeout={300}
											classNames="submenu"
											unmountOnExit
										>
											<ul className="submenu-items mt-2">
												{item.subMenu.map((subItem, index) => (
													<li className="submenu-item rounded-3" key={index}>
														<NavLink to={subItem.path}>{subItem.title}</NavLink>
													</li>
												))}
											</ul>
										</CSSTransition>
									)}
								</div>
							) : (
								<NavLink to={item.path} className="nav-link">
									<FontAwesomeIcon icon={item.icon} />
									<span>{item.title}</span>
								</NavLink>
							)}
						</li>
					))}

					<li className="sidebar-menu-item mt-auto" onClick={onLogoutPress}>
						<span>Logout</span>
					</li>
				</ul>
			</CSSTransition>
		</div>
	);
};

export default Sidebar;
