import Select from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";

import { colors } from "../../themes/colors";

interface DropdownProps extends StateManagerProps {
	label?: string;
	id?: string;
	containerClassName?: string;
	error?: string;
}

const Dropdown = (props: DropdownProps) => {
	const { label, id, containerClassName, error, ...rest } = props;

	return (
		<div className={containerClassName ?? ""}>
			{label && (
				<label htmlFor={id ?? ""} className="form-label">
					{label}
				</label>
			)}

			<Select
				theme={(theme) => ({
					...theme,
					colors: {
						...theme.colors,
					},
				})}
				styles={{
					placeholder: (base) => ({
						...base,
						color: colors.grey,
					}),
				}}
				{...rest}
			/>

			{error && <small className="text-danger">{error}</small>}
		</div>
	);
};

export default Dropdown;
