import {
  CloseButton,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TreeSelect } from "primereact/treeselect";
import { Divider } from "primereact/divider";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import HorizontalInput from "../../components/HorizontalInput";
// import ImageUpload from "../../components/ImageUpload";
import Button from "../../components/Button";
import ScreenLoader from "../../components/ScreenLoader";

import { WEIGHT_UNITS } from "../../utils/constants";
import { toast } from "../../utils/helper";
import {
  getCategories,
  getSubCategories,
} from "../../controller/CategoriesController";
// import { ADD_PRODUCT_VALIDATION } from "../../utils/validations";
import {
  addProduct,
  deleteProduct,
  getProductById,
  updateProduct,
} from "../../controller/ProductsController";
import { ROUTES } from "../../navigators/Routes";
import ActionPopup from "../../components/ActionPopup";
import { getBrands } from "../../controller/BrandsController";
import { FormControl, MenuItem, Select } from "@mui/material";
import { uploadFile } from "../../controller/FilesController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Image } from "primereact/image";
import { Button as PrimeReactButton } from "primereact/button";

const AddEditProduct = () => {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(WEIGHT_UNITS[0].name);
  const [selectedNodeKey, setSelectedNodeKey] = useState<any>({});
  const [selectedSubNodeKey, setselectedSubNodeKey] = useState<any>({});
  const [categoriesDropdownData, setCategoriesDropdownData] = useState([]);
  const [subCategoryDropdownData, setsubCategoryDropdownData] = useState([]);
  const [manualErrorMessages, setManualErrorMessages] = useState({
    category: "",
    images: "",
    brand: "",
  });
  const [images, setImages] = useState<any>("");
  const [brandsDropdownData, setBrandsDropdownData] = useState([]);
  const [brand, setBrand] = useState("");
  const [EditModeImage, setEditModeImage] = useState({
    Image: "",
    Url: "",
    Value: false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  // const EditID = state.id;

  const isEditMode = useMemo(() => {
    return state ? state.value : false;
  }, [state]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages: Blob[] = Array.from(files);
      setImages(newImages[0]);
    }
  };
  const onDeleteImage = () => {
    setImages("");
    if (EditModeImage.Value) {
      setEditModeImage({ Value: false, Image: "", Url: "" });
    }
  };

  const { values, errors, touched, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues: {
        name: "",
        price: "",
        costprice: "",
        weight: "",
        quantity: "",
        OpeningStock: "",
        OSperUnit: "",
        ReorderPoint: "",
      },
      enableReinitialize: true,
      //   validationSchema: ADD_PRODUCT_VALIDATION,
      onSubmit: () => {
        handleSave();
      },
    });

  const handleSavePress = () => {
    const manualErrorMessages = {
      category: "",
      images: "",
    };

    if (Object.values(selectedNodeKey).length === 0) {
      manualErrorMessages.category = "Required";
    }

    if (images.length === 0) {
      manualErrorMessages.images = "Required";
    }

    setManualErrorMessages((prevData) => ({
      ...prevData,
      ...manualErrorMessages,
    }));

    handleSubmit();
  };

  const getCategoriesDropdownData = async () => {
    try {
      const response = await getCategories();
      const subCategory = await getSubCategories();

      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      console.log("response :>> ", response?.data);

      const preparedData = response?.data.map((item: any) => {
        return {
          key: item.id,
          value: item.id,
          label: item.name,
          isParentCategory: true,
        };
      });

      const subCategoryData = subCategory?.data.map((item: any) => {
        return {
          key: item.id,
          value: item.id,
          label: item.name,
        };
      });

      console.log("preparedData :>> ", preparedData);

      setCategoriesDropdownData(preparedData);
      setsubCategoryDropdownData(subCategoryData);
    } catch (error) {
      console.log("error in getting categories :>> ", error);
    }
  };

  const getBrandsDropdownData = async () => {
    try {
      const response = await getBrands();
      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }
      console.log("response :>> ", response);

      setBrandsDropdownData(response?.data ?? []);
    } catch (error) {
      console.log("error in getting brands :>> ", error);
    }
  };

  const getProductDetails = useCallback(
    async (productId: number) => {
      try {
        setLoading(true);
        const response = await getProductById(productId);
        const productDetails = response?.data;
        console.log("productDetails :>> ", productDetails);
        // const splitWeight = productDetails?.weight
        //   ?   productDetails?.weight?.split(" ")
        //   : [];

        // Setting form values
        setValues({
          name: productDetails?.name,
          price: productDetails?.retailSalePrice,
          costprice: productDetails?.costPrice,
          OpeningStock: productDetails?.openingStock,
          ReorderPoint: productDetails?.reorderPoint,
          OSperUnit: productDetails?.openingStockPerUnit,
          weight: productDetails?.weight,
          quantity: productDetails?.totalQuantity,
        });

        setBrand(productDetails?.brand?.id);

        // Setting category and sub category
        if (productDetails?.category) {
          const subCategoryId = productDetails?.category.id;
          setCategoriesDropdownData((prevData) => {
            const flatChildren = prevData.flatMap((item: any) => item);
            const foundSubCategoryData = flatChildren.find(
              (item) => item.key === subCategoryId
            );
            console.log("foundSubCategoryData :>> ", foundSubCategoryData);
            setSelectedNodeKey(foundSubCategoryData);

            return prevData;
          });
        }
        if (productDetails?.subCategory) {
          const subCategoryId = productDetails?.subCategory.id;
          setsubCategoryDropdownData((prevData) => {
            const flatChildren = prevData.flatMap((item: any) => item);
            const foundSubCategoryData = flatChildren.find(
              (item) => item.key === subCategoryId
            );
            console.log("foundSubCategoryData :>> ", foundSubCategoryData);
            setselectedSubNodeKey(foundSubCategoryData);

            return prevData;
          });
        }

        // Setting images

        const imageUrl = productDetails?.image;
        setImages(imageUrl);
        setEditModeImage({ Value: true, Image: "", Url: imageUrl });
      } catch (error) {
        console.log("error in getProductDetails :>> ", error);
      } finally {
        setLoading(false);
      }
    },
    [setValues]
  );

  const handleDropdownSelect = (eventKey: any) => {
    setSelectedItem(eventKey.target.value);
  };

  const imageUpload = async () => {
    let preparedImage = new FormData();
    preparedImage.append("file", images);
    const response = await uploadFile(preparedImage);
    return response.key;
  };

  const handleBrandChange = (event: any) => {
    setBrand(event.target.value);
  };

  const handleGoBack = () => {
    if (location.pathname === ROUTES.ADD_EDIT_PRODUCT) {
      navigate(ROUTES.PRODUCTS, {
        replace: true,
      });
    } else {
      navigate(ROUTES.VENDORDETAILS, {
        replace: true,
        state: true,
      });
    }
  };

  const handleSave = async () => {
    try {
      setSaveLoading(true);
      let image;

      !EditModeImage.Value
        ? (image = await imageUpload())
        : (image = EditModeImage.Image);

      const categoryValue = selectedNodeKey?.value;
      const subCategoryValue = selectedSubNodeKey?.value;

      const preparedData = {
        name: values.name,
        weight: values.weight,
        weightUnit: selectedItem,
        image: image,
        retailSalePrice: values.price,
        costPrice: values.costprice,
        totalQuantity: values.quantity,
        openingStock: values.OpeningStock,
        openingStockPerUnit: values.OSperUnit,
        reorderPoint: values.ReorderPoint,
        category: categoryValue,
        subCategory: subCategoryValue,
        brand: brand,
      };

      const addEditProductFunction = isEditMode ? updateProduct : addProduct;

      console.log(preparedData);

      const response = await addEditProductFunction(preparedData, state?.id);

      console.log("response :>> ", response);

      // Iterate over FormData entries and log key-value pairs

      if (response?.statusCode === 400 || response?.statusCode === 404) {
        toast.error(response?.message);
        return;
      }

      if (isEditMode) {
        toast.success("Product updated successfully");
      } else {
        toast.success("Product added successfully");
      }

      handleGoBack();
    } catch (error) {
      console.log("error in handleSave :>> ", error);
    } finally {
      setSaveLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      hideDeletePopup();
      setLoading(true);
      const response = await deleteProduct(state?.productId);
      console.log("response :>> ", response);
      if (response?.statusCode === 400) {
        toast.error(response?.message);
        return;
      }

      toast.success("Product deleted successfully");
      handleGoBack();
    } catch (error) {
      console.log("error in deleting product :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  const hideDeletePopup = () => {
    setDeletePopupVisible(false);
  };

  const getScreenData = useCallback(async () => {
    try {
      await getCategoriesDropdownData();
      await getBrandsDropdownData();

      if (isEditMode) {
        const productId = state?.id;
        await getProductDetails(productId);
      }
    } catch (error) {
      console.log("error in getting categories :>> ", error);
      toast.error("Something went wrong");
    }
  }, [state, getProductDetails, isEditMode]);

  useEffect(() => {
    getScreenData();
  }, [getScreenData]);

  const profit = parseInt(values.costprice) - parseInt(values.price);
  const margin = (profit / parseInt(values.costprice)) * 100;

  return (
    <div>
      <ScreenLoader loading={loading} />

      <ActionPopup
        visible={deletePopupVisible}
        title="Delete Product"
        message="Are you sure you want to delete this product?"
        confirmText="Delete"
        cancelText="Cancel"
        onConfirm={onDelete}
        onCancel={hideDeletePopup}
      />

      <div className="d-flex align-items-center justify-content-between py-3 px-4 bg-white">
        <h3>{isEditMode ? "Edit" : "New"} Product</h3>
        <div className="d-flex align-items-center">
          {/* {isEditMode && (
            <FontAwesomeIcon
              icon={faTrash}
              style={{ color: colors.red, cursor: "pointer" }}
              onClick={showDeletePopup}
              size="lg"
              className="me-3"
            />
          )} */}
          <CloseButton onClick={handleGoBack} />
        </div>
      </div>

      <div className="py-3 px-4">
        <div className="d-flex flex-column align-items-start justify-content-between gap-3">
          <div className="w-100">
            <HorizontalInput
              label="Product Name"
              isMandatory
              value={values.name}
              onChange={handleChange}
              error={touched.name ? errors.name : ""}
              name="name"
            />
          </div>

          <div className="w-100">
            <div className="flex w-100 gap-5">
              <div className="w-50">
                <HorizontalInput
                  label="Category"
                  isMandatory
                  containerClassName="mt-3"
                  customInput={
                    <>
                      <TreeSelect
                        value={selectedNodeKey?.value}
                        onNodeSelect={(event: any) => {
                          console.log("event :>> ", event);
                          setSelectedNodeKey(event.node);
                          setManualErrorMessages({
                            ...manualErrorMessages,
                            category: "",
                          });
                        }}
                        options={categoriesDropdownData}
                        style={{ width: "100%" }}
                        className="p-2"
                        name="category"
                      />
                      {manualErrorMessages.category && (
                        <small className="text-danger">
                          {manualErrorMessages.category}
                        </small>
                      )}
                    </>
                  }
                />
              </div>
              <div className="w-50">
                <HorizontalInput
                  label="Sub Category"
                  isMandatory
                  containerClassName="mt-3"
                  customInput={
                    <>
                      <TreeSelect
                        value={selectedSubNodeKey?.value}
                        onNodeSelect={(event: any) => {
                          console.log("event :>> ", event);
                          setselectedSubNodeKey(event.node);
                          setManualErrorMessages({
                            ...manualErrorMessages,
                            category: "",
                          });
                        }}
                        options={subCategoryDropdownData}
                        style={{ width: "100%" }}
                        className="p-2"
                        name="category"
                      />
                      {manualErrorMessages.category && (
                        <small className="text-danger">
                          {manualErrorMessages.category}
                        </small>
                      )}
                    </>
                  }
                />
              </div>
            </div>
            <div className="flex w-100 gap-5">
              <div className="w-50">
                <HorizontalInput
                  label="Brand Name"
                  isMandatory
                  containerClassName="mt-3"
                  onChange={handleChange}
                  error={
                    manualErrorMessages.brand ? manualErrorMessages.brand : ""
                  }
                  customInput={
                    <FormControl fullWidth>
                      <Select
                        value={brand}
                        onChange={handleBrandChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={{
                          backgroundColor: "white",
                          height: "2.5rem",
                        }}
                      >
                        {brandsDropdownData.map((brand: any) => (
                          <MenuItem key={brand.id} value={brand.id}>
                            {brand.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                />
              </div>
              <div className="w-50">
                <HorizontalInput
                  label="Weight"
                  isMandatory
                  containerClassName="mt-3"
                  customInput={
                    <>
                      <InputGroup>
                        <Form.Control
                          aria-label="Enter Weight"
                          value={values.weight}
                          onChange={handleChange}
                          name="weight"
                          type="number"
                        />

                        <DropdownButton
                          variant="outline-secondary"
                          title={selectedItem || "Dropdown"}
                          id="input-group-dropdown-2"
                          align="end"
                        >
                          {WEIGHT_UNITS.map((unit) => (
                            <Dropdown.Item
                              key={unit.id}
                              as={"button"}
                              style={{
                                cursor: "pointer",
                              }}
                              value={unit.name}
                              onClick={handleDropdownSelect}
                            >
                              {unit.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </InputGroup>

                      {touched.weight && errors.weight && (
                        <small className="text-danger">{errors.weight}</small>
                      )}
                    </>
                  }
                />
              </div>
            </div>
          </div>

          {/* Upload Image */}
          <div className="w-100">
            {!images ? (
              <div>
                <label
                  htmlFor="image-upload"
                  className="border-dashed border-light-subtle rounded d-flex flex-column justify-content-center align-items-center bg-white"
                  style={{
                    height: "200px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    id="image-upload"
                    style={{ visibility: "hidden" }}
                  />
                  <FontAwesomeIcon icon={faCloudArrowUp} size="2xl" />
                  <p className="w-75 text-center text-secondary">
                    Drag and drop your image here or click to upload
                  </p>
                </label>
              </div>
            ) : (
              <div className="image-upload">
                <div className="image-preview">
                  <div className="">
                    <Image
                      src={
                        EditModeImage.Value
                          ? images
                          : URL.createObjectURL(images)
                      }
                      alt={`Brand`}
                      width="300"
                      height="200"
                      preview
                      style={{
                        objectFit: "contain",
                      }}
                    />
                    <PrimeReactButton
                      icon="pi pi-trash"
                      className="p-button-rounded p-button-danger p-button-outlined p-button-sm p-button-text"
                      onClick={onDeleteImage}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Purchase & Sales Information */}
          <div className="flex flex-column gap-4 mt-5 w-100">
            <h2>Purchase & Sales Information</h2>
            <div className="flex w-100 gap-5">
              <div className="flex flex-column w-50">
                <label htmlFor="SalePrice">Retail Sale Price</label>
                <div className="flex w-100">
                  <div className="border p-2 px-4 rounded-start-3 bg-white">
                    INR
                  </div>
                  <input
                    type="number"
                    id="SalePrice"
                    value={values.price}
                    onChange={handleChange}
                    className="border p-2 rounded-end-3 w-100"
                    name="price"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-column w-50">
                <label htmlFor="costPrice">Cost Price</label>
                <div className="flex w-100">
                  <div className="border p-2 px-4 rounded-start-3 bg-white">
                    INR
                  </div>
                  <input
                    type="number"
                    id="costPrice"
                    value={values.costprice}
                    onChange={handleChange}
                    className="border p-2 rounded-end-3 w-100"
                    name="costprice"
                    required
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex flex-column w-50">
                <label htmlFor="SalePrice">Retail Margin & Profit</label>
                <div
                  className="flex flex-column gap-4 w-75 p-3 rounded-3"
                  style={{ backgroundColor: "#ECECEC" }}
                >
                  <div className="flex justify-content-between w-100">
                    <span>Margin</span>
                    <span>{margin ? margin + "%" : "0"}</span>
                  </div>
                  <div className="flex justify-content-between w-100">
                    <span>Profit</span>
                    <span>{"₹" + (profit ? profit : "0")}</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Quantity & Reorder Point */}
            <div className="flex flex-column gap-4 mt-5 w-100">
              <h2>Quantity & Reorder Point</h2>
              <div className="flex gap-3">
                <div className="w-25">
                  <HorizontalInput
                    label="Total Quantity"
                    isMandatory
                    containerClassName="mt-3"
                    type="number"
                    name="quantity"
                    value={values.quantity}
                    onChange={handleChange}
                    error={touched.quantity ? errors.quantity : ""}
                  />
                </div>
                <div className="w-25">
                  <HorizontalInput
                    label="Opening Stock"
                    isMandatory
                    containerClassName="mt-3"
                    type="number"
                    name="OpeningStock"
                    value={values.OpeningStock}
                    onChange={handleChange}
                    error={touched.OpeningStock ? errors.OpeningStock : ""}
                  />
                </div>

                <div className="flex flex-column justify-content-end w-25">
                  <label htmlFor="SalePrice">Opening Stock Per Unit</label>
                  <div className="flex w-100">
                    <div className="border p-2 px-4 rounded-start-3 bg-white">
                      ₹
                    </div>
                    <input
                      type="number"
                      id="SalePrice"
                      value={values.OSperUnit}
                      onChange={handleChange}
                      className="border p-2 rounded-end-3 w-100"
                      name="OSperUnit"
                      required
                    />
                  </div>
                </div>
                <div className="w-25">
                  <HorizontalInput
                    label="Reorder Point"
                    isMandatory
                    containerClassName="mt-3"
                    type="number"
                    name="ReorderPoint"
                    value={values.ReorderPoint}
                    onChange={handleChange}
                    error={touched.ReorderPoint ? errors.ReorderPoint : ""}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <div className="py-3 px-4 bg-light flex gap-3 justify-content-end">
        <Button
          variant="dark"
          className="btn btn-secondary px-5"
          onClick={() => {}}
          disabled={saveLoading}
        >
          <span>Cancel</span>
        </Button>
        <Button
          className="btn btn-primary px-7 text-white border-none"
          onClick={() => {
            handleSavePress();
          }}
          style={
            isEditMode
              ? { backgroundColor: "#FF2063" }
              : { backgroundColor: "#3D348B" }
          }
          isLoading={saveLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddEditProduct;
