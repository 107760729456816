import FilterButton from "../../components/Filterbutton/FilterButton";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faPen,
	faFilePdf,
	faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import "./style.css";

const Bills = () => {
	const handdlecheked = () => {};

	return (
		<div className="p-3 flex flex-column gap-4">
			{/* Page Title ,Filter & AddBill Button*/}
			<div className="flex justify-content-between">
				<h4 className="mb-4 fw-bold">All Bills</h4>
				<div className="d-flex w-50 gap-3">
					<div className="flex w-50">
						<div
							className="border w-50 rounded-start-3 btnhover"
							style={{ height: "80%" }}
						>
							<Button
								variant=""
								className="flex align-items-center text-sm fw-semibold h-100 border-none gap-2 hover:text-white"
								style={{ color: "#5A5A5A" }}
							>
								Download PDF
								<FontAwesomeIcon icon={faFilePdf} size="lg" />
							</Button>
						</div>
						<div
							className="border w-4 rounded-end-3 btnhover"
							style={{ height: "80%" }}
						>
							<Button
								variant=""
								className="flex align-items-center flex-row-reverse w-100 text-sm fw-semibold h-100 border-none gap-2 hover:text-white"
								style={{ color: "#5A5A5A" }}
							>
								<FontAwesomeIcon icon={faPrint} size="lg" />
								Print
							</Button>
						</div>
					</div>
					<div>
						<FilterButton
							titles={["All", "Draft", "Paid", "Pending", "Overdue", "Month"]}
							Name={"Filter By"}
						/>
					</div>
					<div>
						<Button
							variant=""
							className="bg-black text-white flex align-items-center gap-2"
						>
							<FontAwesomeIcon icon={faPlus} />
							Add Bill
						</Button>
					</div>
				</div>
			</div>
			<div className="border border-2 rounded-3">
				<Table className="rounded-5 mb-0 transparent-table">
					<thead>
						<tr>
							<th className="p-3">
								<div className="flex justify-content-center align-items-center">
									<input type="checkbox" />
								</div>
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Date
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Bill Number
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Reference No.
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Vendor Name
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Status
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Due Date
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}>
								Amount
							</th>
							<th className="pt-3 pb-3" style={{ color: "#534D59" }}></th>
						</tr>
					</thead>
					<tbody className="text-sm">
						<tr>
							<td className="pt-3 pb-3">
								<div className="flex justify-content-center align-items-center">
									<input type="checkbox" onChange={handdlecheked} />
								</div>
							</td>
							<td className="pt-3 pb-3">12/03/2024</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">Arshad Qureshi</td>
							<td className="fw-bold pt-3 pb-3" style={{ color: "#BDBDBD" }}>
								DRAFT
							</td>
							<td className="pt-3 pb-3">31/08/2024</td>
							<td className="pt-3 pb-3">&#8377; 4500</td>
							<td className="pt-3 pb-3">
								<FontAwesomeIcon icon={faPen} size="lg" />
							</td>
						</tr>
						<tr>
							<td className="pt-3 pb-3">
								<div className="flex justify-content-center align-items-center">
									<input type="checkbox" />
								</div>
							</td>
							<td className="pt-3 pb-3">12/03/2024</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">Arshad Qureshi</td>
							<td className="fw-bold pt-3 pb-3" style={{ color: "#43A047" }}>
								PAID
							</td>
							<td className="pt-3 pb-3">31/08/2024</td>
							<td className="pt-3 pb-3">&#8377; 4500</td>
							<td className="pt-3 pb-3">
								<FontAwesomeIcon icon={faPen} size="lg" />
							</td>
						</tr>
						<tr>
							<td className="pt-3 pb-3">
								<div className="flex justify-content-center align-items-center">
									<input type="checkbox" />
								</div>
							</td>
							<td className="pt-3 pb-3">12/03/2024</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">Arshad Qureshi</td>
							<td className="fw-bold pt-3 pb-3" style={{ color: "#FF5252" }}>
								OVER DUE BY 10 DAYS
							</td>
							<td className="pt-3 pb-3">31/08/2024</td>
							<td className="pt-3 pb-3">&#8377; 4500</td>
							<td className="pt-3 pb-3">
								<FontAwesomeIcon icon={faPen} size="lg" />
							</td>
						</tr>
						<tr>
							<td className="pt-3 pb-3">
								<div className="flex justify-content-center align-items-center">
									<input type="checkbox" />
								</div>
							</td>
							<td className="pt-3 pb-3">12/03/2024</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">00596</td>
							<td className="pt-3 pb-3">Arshad Qureshi</td>
							<td className="fw-bold pt-3 pb-3" style={{ color: "#2962FF" }}>
								PENDING
							</td>
							<td className="pt-3 pb-3">31/08/2024</td>
							<td className="pt-3 pb-3">&#8377; 4500</td>
							<td className="pt-3 pb-3">
								<FontAwesomeIcon icon={faPen} size="lg" />
							</td>
						</tr>
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default Bills;
