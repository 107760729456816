import Lottie, { LottieComponentProps } from "lottie-react";

interface Props extends LottieComponentProps {
	animationData: any;
	title?: string;
	message?: string;
}

const NoData = (props: Props) => {
	const { animationData, title = "", message, ...rest } = props;
	return (
		<div>
			<Lottie
				animationData={animationData}
				style={{
					width: "25rem",
					height: "25rem",
					margin: "auto",
				}}
				loop={false}
				{...rest}
			/>
			<h3 className="text-center">{title}</h3>
			<p className="text-center">{message}</p>
		</div>
	);
};

export default NoData;
