import localforage from "localforage";
import { Slide, toast as UIToast } from "react-toastify";

import { STORAGE_KEYS } from "./constants";
import { TOAST_TYPE } from "./types";

export const isUserAuthenticated = async () => {
	try {
		const isUserAuthenticated = await localforage.getItem(
			STORAGE_KEYS.IS_USER_AUTHENTICATED
		);
		console.log("isUserAuthenticated :>> ", isUserAuthenticated);
		return isUserAuthenticated;
	} catch (error) {
		console.error("Error getting user authentication status:", error);
		return false;
	}
};

export const updateUserAuthenticationStatus = async (
	status: boolean,
	userData: any
) => {
	try {
		await localforage.setItem(STORAGE_KEYS.IS_USER_AUTHENTICATED, status);
		await localforage.setItem(STORAGE_KEYS.USER_DATA, userData);
	} catch (error) {
		console.error("Error updating user authentication status:", error);
	}
};

export const getUserData = async () => {
	try {
		const userData = await localforage.getItem(STORAGE_KEYS.USER_DATA);

		if (!userData) {
			return null;
		}
		return JSON.parse(userData as any);
	} catch (error) {
		console.error("Error getting user data:", error);
		return null;
	}
};

export const getUserId = async () => {
	try {
		const userData = await getUserData();
		return userData?.id;
	} catch (error) {
		console.error("Error getting user id:", error);
		return "";
	}
};

const showToast = (data: TOAST_TYPE) => {
	UIToast[data.type](data.message, {
		position: "top-center",
		autoClose: 2000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "colored",
		transition: Slide,
	});
};

export const toast = {
	success: (message: string) => {
		showToast({ message, type: "success" });
	},
	error: (message: string) => {
		showToast({ message, type: "error" });
	},
	info: (message: string) => {
		showToast({ message, type: "info" });
	},
	warning: (message: string) => {
		showToast({ message, type: "warning" });
	},
};
