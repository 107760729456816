import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { colors } from "../../themes/colors";
import { Image } from "react-bootstrap";

interface ViewBrandProps {
  data: any;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const ViewBrand = (props: ViewBrandProps) => {
  const { data, onEdit, onDelete } = props;
  console.log(data);
  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <p
          className="ml-6 mb-0 mr-0 mt-0"
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#ACACAC",
          }}
        >
          Image
        </p>
        <p
          className="ml-8 mb-0 mr-0 mt-0"
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#ACACAC",
          }}
        >
          Name
        </p>
        <p
          className="mb-0 ml-auto"
          style={{
            fontWeight: "400",
            fontSize: "16px",
            color: "#ACACAC",
            marginRight: "8rem",
          }}
        >
          Total Products
        </p>
      </div>
      <div>
        {data.length >= 0 &&
          data.map((item: any) => {
            return (
              <div
                key={item.id}
                className="mb-4"
                style={{
                  border: "1px solid #E4E4E4",
                  backgroundColor: "#F9F9F9",
                  paddingLeft: "16px",
                  paddingRight: "30px",
                  paddingBlock: "20px",
                  borderRadius: "20px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center	">
                    <div className="d-flex align-items-center">
                      <Image
                        className={"ml-4"}
                        src={item?.imageUrl}
                        rounded
                        width={"80px"}
                        height={"80px"}
                        style={{
                          objectFit: "contain",
                        }}
                      />
                      <h3 className="ml-6 fw-semibold text-1xl">
                        {item?.name}
                      </h3>
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <p
                      className="mr-7 mb-0"
                      style={{
                        color: "#3E3E3E",
                        fontSize: "35px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      {item?.totalProducts}
                    </p>

                    <div>
                      <FontAwesomeIcon
                        icon={faPencil}
                        style={{ cursor: "pointer" }}
                        className="mr-4"
                        onClick={() => onEdit(item)}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: colors.red, cursor: "pointer" }}
                        onClick={() => onDelete(item.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default memo(ViewBrand);
