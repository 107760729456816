import { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import "./Input.css";

interface InputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	label?: string;
	placeholderColor?: string;
	placeholderSize?: string;
	error?: string;
	isPassword?: boolean;
	containerStyle?: React.CSSProperties;
}

const Input = (props: InputProps) => {
	const {
		label,
		className,
		placeholderColor,
		placeholderSize,
		error,
		isPassword,
		containerStyle,
		...rest
	} = props;

	const [passwordVisible, setPasswordVisible] = useState(!isPassword);

	const typeValue = useMemo(() => {
		if (isPassword) {
			return passwordVisible ? "text" : "password";
		}

		return props.type || "text";
	}, [passwordVisible, props.type, isPassword]);

	return (
		<div style={containerStyle}>
			{label && (
				<label htmlFor={rest.id} className="form-label">
					{label}
				</label>
			)}
			<div className="d-flex flex-row align-items-center position-relative">
				<input
					{...rest}
					className={`form-control ${className ?? ""}`}
					type={typeValue}
				/>

				{isPassword && (
					<div
						className="position-absolute end-0 me-3"
						style={{
							top: "45%",
						}}
						onClick={() => setPasswordVisible(!passwordVisible)}
					>
						<FontAwesomeIcon
							icon={passwordVisible ? faEyeSlash : faEye}
							className={"text-secondary cursor-pointer"}
						/>
					</div>
				)}
			</div>

			{error && <small className="text-danger">{error}</small>}
		</div>
	);
};

export default Input;
