import { useState } from "react";
import Input from "../../components/Input";
import { colors } from "../../themes/colors";
import { FormCheck } from "react-bootstrap";
import Button from "../../components/Button";
import { registerUser } from "../../services/AuthService";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";

type RegisterProps = {};

const Register = (props: RegisterProps) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    storeName: "",
    storeAddress: "",
    email: "",
    mobileNumber: "",
    password: "",
    isAgreed: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    storeName: "",
    storeAddress: "",
    email: "",
    mobileNumber: "",
    password: "",
    isAgreed: "",
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));

    setErrorMessages((prevState) => ({
      ...prevState,
      [e.target.id]: "",
    }));
  };

  const validateInputs = () => {
    let isValid = true;
    let errorMessages = {
      firstName: "",
      lastName: "",
      storeName: "",
      storeAddress: "",
      email: "",
      mobileNumber: "",
      password: "",
      isAgreed: "",
    };

    if (
      !formValues.firstName ||
      (formValues.firstName && formValues.firstName.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.firstName = "Please enter your first name";
    }

    if (
      !formValues.lastName ||
      (formValues.lastName && formValues.lastName.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.lastName = "Please enter your last name";
    }

    if (
      !formValues.storeName ||
      (formValues.storeName && formValues.storeName.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.storeName = "Please enter your store name";
    }

    if (
      !formValues.storeAddress ||
      (formValues.storeAddress && formValues.storeAddress.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.storeAddress = "Please enter your store address";
    }

    if (
      !formValues.email ||
      (formValues.email && formValues.email.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.email = "Please enter your email address";
    } else if (
      !formValues.email.includes("@") &&
      !formValues.email.includes(".")
    ) {
      isValid = false;
      errorMessages.email = "Please enter a valid email address";
    }

    if (
      !formValues.mobileNumber ||
      (formValues.mobileNumber && formValues.mobileNumber.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.mobileNumber = "Please enter your mobile number";
    } else if (isNaN(parseInt(formValues.mobileNumber))) {
      isValid = false;
      errorMessages.mobileNumber = "Please enter a valid mobile number";
    } else if (formValues.mobileNumber.length !== 10) {
      isValid = false;
      errorMessages.mobileNumber = "Mobile number must be 10 digits long";
    }

    if (
      !formValues.password ||
      (formValues.password && formValues.password.trim().length === 0)
    ) {
      isValid = false;
      errorMessages.password = "Please enter your password";
    } else if (formValues.password.length < 8) {
      isValid = false;
      errorMessages.password = "Password must be at least 8 characters long";
    }

    if (!formValues.isAgreed) {
      isValid = false;
      errorMessages.isAgreed = "Please agree to the terms of service";
    }

    setErrorMessages(errorMessages);

    return isValid;
  };

  const onRegisterPress = () => {
    setLoading(true);
    const isValid = validateInputs();
    if (isValid) {
      register();
    } else {
      setLoading(false);
    }
  };

  const register = async () => {
    try {
      const preparedData = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        storeName: formValues.storeName,
        storeAddress: formValues.storeAddress,
        email:formValues.email,
        mobileNumber: formValues.mobileNumber,
        password: formValues.password,
      };
      console.log(preparedData);

      const response = await registerUser(preparedData);
      console.log("response :>> ", response);

      if (response && response.statusCode === 400) {
        alert(response.message);
        return;
      }

      navigate(ROUTES.LOGIN, { replace: true });
    } catch (error) {
      console.log("error in register :>> ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex gap-5"
      style={{
        background: colors.lightBlack,
        height: "100vh",
        paddingLeft: "15vw",
        paddingRight: "15vw",
        paddingTop: "1vh",
      }}
    >
      <div
        style={{
          width: "40vw",
        }}
        className="d-flex flex-column justify-content-center gap-3 align-items-center text-white"
      >
        <h1>
          Start your <span className="text-warning">Urbanlyfe</span> journey
          today.
        </h1>
        <p>
          Running a retail business is easy with Zakya. We help you sell better,
          manage your entire business, and join the digital revolution.
        </p>
      </div>

      <div
        className="bg-white rounded-top p-5"
        style={{
          width: "50vw",
        }}
      >
        <div>
          <h4 className="text-center">
            Get started with your 15-day free trial.
          </h4>
          <p className="text-center text-secondary">No credit card needed.</p>
        </div>

        <div
          className="mt-2 flex flex-column justify-content-between"
          style={{ height: "80%" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <Input
              type="text"
              id="firstName"
              aria-describedby="firstNameHelp"
              placeholder="First Name"
              onChange={onInputChange}
              value={formValues.firstName}
              error={errorMessages.firstName}
            />
            <Input
              type="text"
              id="lastName"
              aria-describedby="lastNameHelp"
              placeholder="Last Name"
              onChange={onInputChange}
              value={formValues.lastName}
              error={errorMessages.lastName}
            />
          </div>
          <Input
            type="text"
            id="storeName"
            aria-describedby="storeNameHelp"
            placeholder="Store Name"
            onChange={onInputChange}
            value={formValues.storeName}
            error={errorMessages.storeName}
            className="mt-3"
          />
          <Input
            type="text"
            id="storeAddress"
            aria-describedby="storeAddressHelp"
            placeholder="Store Address"
            onChange={onInputChange}
            value={formValues.storeAddress}
            error={errorMessages.storeAddress}
            className="mt-3"
          />
          <Input
            type="email"
            id="email"
            aria-describedby="emailHelp"
            placeholder="Email address"
            onChange={onInputChange}
            value={formValues.email}
            error={errorMessages.email}
            className="mt-3"
          />
          <div className="mt-3">
            {/* <Input
							id="mobileCode"
							aria-describedby="mobileCodeHelp"
							disabled
							value={"91"}
							style={{
								width: "20%",
							}}
						/> */}
            <Input
              type="text"
              id="mobileNumber"
              aria-describedby="mobileNumberHelp"
              placeholder="Mobile Number"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
                onInputChange(e);
              }}
              value={formValues.mobileNumber}
              error={errorMessages.mobileNumber}
              maxLength={10}
            />
          </div>

          <Input
            isPassword
            id="password"
            aria-describedby="password"
            placeholder="Enter password"
            onChange={onInputChange}
            value={formValues.password}
            error={errorMessages.password}
            className="mt-3"
          />

          <FormCheck
            id="isAgreed"
            type="checkbox"
            label="I agree to the Terms of Service and Privacy Policy"
            className="mt-3"
            style={{
              fontSize: "0.8rem",
            }}
            value={formValues.isAgreed as any}
            onChange={onInputChange}
            isInvalid={!!errorMessages.isAgreed}
          />
        </div>

        <Button
          type="submit"
          variant="warning"
          className="w-100 mt-3"
          onClick={onRegisterPress}
          isLoading={loading}
        >
          Register
        </Button>
      </div>
    </div>
  );
};

export default Register;
