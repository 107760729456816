import {
	faChevronDown,
	faChevronRight,
	faPencil,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, useState } from "react";
import { colors } from "../../themes/colors";
import { Image } from "react-bootstrap";

interface ViewCategoryProps {
	data: any;
	onEdit: (id: string, isSubCategory: boolean) => void;
	onDelete: (
		id: string,
		isSubCategory: boolean,
		hasSubCategoryData?: boolean
	) => void;
}

const ViewCategory = (props: ViewCategoryProps) => {
	const { data, onEdit, onDelete } = props;

	const [expandedId, setExpandedId] = useState<null | number>(null);

	const onExpandClick = (id: number) => {
		setExpandedId((prev) => (prev === id ? null : id));
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-2">
				<p
					className="ml-8 mb-0 mr-0 mt-0"
					style={{
						fontWeight: "400",
						fontSize: "16px",
						color: "#ACACAC",
					}}
				>
					Image
				</p>
				<p
					className="ml-8 mb-0 mr-0 mt-0"
					style={{
						fontWeight: "400",
						fontSize: "16px",
						color: "#ACACAC",
					}}
				>
					Name
				</p>
				<p
					className="mb-0 ml-auto"
					style={{
						fontWeight: "400",
						fontSize: "16px",
						color: "#ACACAC",
						marginRight: "8rem",
					}}
				>
					Total Products
				</p>
			</div>
			<div>
				{data.map((item: any) => {
					return (
						<div
							key={item.id}
							className="mb-4"
							style={{
								border: "1px solid #E4E4E4",
								backgroundColor: "#F9F9F9",
								paddingLeft: "16px",
								paddingRight: "30px",
								paddingBlock: "20px",
								borderRadius: "20px",
							}}
						>
							<div className="d-flex align-items-center justify-content-between">
								<div className="d-flex align-items-center">
									{item.subCategory.length > 0 && (
										<FontAwesomeIcon
											icon={
												item.id === expandedId ? faChevronDown : faChevronRight
											}
											size="xl"
											style={{
												cursor: "pointer",
											}}
											onClick={() => onExpandClick(item.id)}
										/>
									)}
									<div className="d-flex align-items-center">
										<Image
											className={
												item.subCategory.length >= 0 ? "ml-5" : "ml-6"
											}
											src={item.imageUrl}
											rounded
											width={"80px"}
											height={"80px"}
											style={{
												objectFit: "contain",
											}}
										/>
										<h3 className="ml-6">{item.name}</h3>
									</div>
								</div>

								<div className="d-flex align-items-center">
									<p
										className="mr-7 mb-0"
										style={{
											color: "#3E3E3E",
											fontSize: "35px",
											fontWeight: "600",
											textAlign: "center",
										}}
									>
										{item?.productCount}
									</p>

									<div>
										<FontAwesomeIcon
											icon={faPencil}
											style={{ cursor: "pointer" }}
											className="mr-4"
											onClick={() => onEdit(item, false)}
										/>
										<FontAwesomeIcon
											icon={faTrash}
											style={{ color: colors.red, cursor: "pointer" }}
											onClick={() =>
												onDelete(
													item.id,
													false,
													item.subCategory.length > 0
												)
											}
										/>
									</div>
								</div>
							</div>
							{item.id === expandedId &&
								item.subCategory.length > 0 &&
								item.subCategory.map((subItem: any) => {
									return (
										<div
											key={subItem.id}
											className="ms-5 mt-4 d-flex align-items-center justify-content-between bg-white"
											style={{
												border: "1px solid #E4E4E4",
												// paddingLeft: "16px",
												paddingRight: "20px",
												paddingBlock: "10px",
												borderRadius: "20px",
											}}
										>
											<div className="d-flex align-items-center">
												<Image
													className="ml-3"
													src={subItem.imageUrl}
													rounded
													width={"80px"}
													height={"80px"}
													style={{
														objectFit: "contain",
													}}
												/>
												<h5 className="ml-5">{subItem.name}</h5>
											</div>

											<div>
												<FontAwesomeIcon
													icon={faPencil}
													style={{ cursor: "pointer" }}
													className="me-4"
													onClick={() => onEdit(subItem, true)}
												/>
												<FontAwesomeIcon
													icon={faTrash}
													style={{ color: colors.red, cursor: "pointer" }}
													onClick={() => onDelete(subItem.id, true)}
												/>
											</div>
										</div>
									);
								})}
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default memo(ViewCategory);
