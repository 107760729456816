import * as Yup from "yup";

export const ADD_CATEGORY_VALIDATION = Yup.object({
	categoryName: Yup.string()
		.trim()
		.min(3, "Must be 3 characters or more")
		.required("Required"),
});

export const ADD_PRODUCT_VALIDATION = Yup.object({
	name: Yup.string()
		.trim()
		.min(3, "Must be 3 characters or more")
		.required("Required"),
	// description: Yup.string().trim().required("Required"),
	price: Yup.number().required("Required"),
	// brandName: Yup.string().trim().required("Required"),
	// countryOrigin: Yup.string().trim().required("Required"),
	// ingredients: Yup.string().trim().required("Required"),
	// benefits: Yup.string().trim().required("Required"),
	// concern: Yup.string().trim().required("Required"),
	weight: Yup.string().trim().required("Required"),
	// aboutBrand: Yup.string().trim().required("Required"),
	quantity: Yup.number().required("Required"),
	// manufacturedBy: Yup.string().required("Required"),
	// bestBefore: Yup.number().required("Required"),
});

export const ADD_BRAND_VALIDATION = Yup.object({
	brandName: Yup.string()
		.trim()
		.min(3, "Must be 3 characters or more")
		.required("Required"),
});
