import PurchaseDetails from "../../components/PurchaseDetails/PurchaseDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../../components/Filterbutton/FilterButton";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import VendorTransaction from "../../components/VendorFullDetailsComponent/VendorTransaction";
import { ROUTES } from "../../navigators/Routes";
const PurchaseOrders = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="p-3">
        <h4 className="mb-4 fw-bold">Purchase Order</h4>
        <div className="p-3 flex flex-column gap-5">
          <PurchaseDetails />
          {/* Search ,Filter,AddPurchaseOrder */}
          <div className="flex justify-content-between">
            <div className="search-container" style={{ width: "55%" }}>
              <input
                type="search"
                className="search-input outline-none border rounded-3 p-2 px-3"
                placeholder="Search Product"
              />
              <FontAwesomeIcon
                icon={faSearch}
                color="#9F9F9F"
                className="mr-2 search-icon"
              />
            </div>
            <div>
              <FilterButton
                Name={"Filter By"}
                titles={[
                  "On Process",
                  "Completed",
                  "Draft",
                  "Paid",
                  "Payment Due",
                ]}
              />
            </div>
            <div className="flex">
              <Button variant="" className="bg-black text-white px-4" 
                  onClick={() => {
                    navigate(ROUTES.NEWPURCHASEORDERS);
                  }}>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="mr-2"
                />
                Add Purchase order
              </Button>
            </div>
          </div>
          <VendorTransaction />
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrders;
