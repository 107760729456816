import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button";

interface Props {
  title: string;
  show: boolean;
  handleClose: () => void;
  handleSave: () => void;
  body: React.ReactNode;
  loading?: boolean;
  size?: "sm" | "lg" | "xl";
  Savetext?:String;
}

const UiModal = (props: Props) => {
  const { title, show, handleClose, handleSave, body, loading,Savetext, size } = props;
  return (
    <Modal
      size={size}
      show={show}
      onHide={handleClose}
      contentClassName="p-4 rounded-4"
      backdrop="static"
    >
      <div className="flex flex-column w-100 gap-4">
        <Modal.Title className="fw-bold">{title}</Modal.Title>
        <Modal.Body className="mb-3 p-0">{body}</Modal.Body>
        <div className="flex gap-3 justify-content-end">
          <Button variant="" onClick={handleClose} className=" border border-2" disabled={loading}>
            Cancle
          </Button>
          <Button
            variant=""
            className="bg-black text-white px-4"
            onClick={handleSave}
            isLoading={loading}
          >
            {Savetext ? Savetext : "Add"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UiModal;
