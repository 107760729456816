import "./DataOverview.css";

type Props = {
	title: string;
	value: string;
	icon: string;
	iconBgColor: string;
	title2: string;
	value2: string;
	icon2: string;
	iconBgColor2: string;
};

const DataOverview = (props: Props) => {
	const {
		title,
		value,
		icon,
		iconBgColor,
		title2,
		value2,
		icon2,
		iconBgColor2,
	} = props;

	return (
		<div className="container-shadow flex px-5 py-3">
			<div className="d-flex">
				<div
					className="d-flex justify-content-center align-items-center rounded-circle"
					style={{
						width: "5rem",
						height: "5rem",
						background: iconBgColor,
					}}
				>
					<img src={icon} alt="" />
				</div>

				<div className="ml-3">
					<p
						className="m-0"
						style={{
							fontWeight: "400",
							fontSize: "14px",
							color: "#ACACAC",
						}}
					>
						{title}
					</p>

					<h2
						style={{
							color: "#333333",
						}}
					>
						{value}
					</h2>
				</div>
			</div>

			<div
				className="ml-6 mr-4"
				style={{
					border: "1px solid #F0F0F0",
				}}
			/>

			<div className="d-flex">
				<div
					className="d-flex justify-content-center align-items-center rounded-circle"
					style={{
						width: "5rem",
						height: "5rem",
						background: iconBgColor2,
					}}
				>
					<img src={icon2} alt="" />
				</div>

				<div className="ml-3">
					<p
						className="m-0"
						style={{
							fontWeight: "400",
							fontSize: "14px",
							color: "#ACACAC",
						}}
					>
						{title2}
					</p>

					<h2
						style={{
							color: "#333333",
						}}
					>
						{value2}
					</h2>
				</div>
			</div>
		</div>
	);
};

export default DataOverview;
