import { Image } from "react-bootstrap";
import { images } from "../../assets";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../navigators/Routes";

const ViewBrandVendor = () => {
	const navigate = useNavigate();

	const vendordetails = () => {
		navigate(ROUTES.VENDORDETAILS);
	};
	return (
		<div
			className="flex flex-column p-3 px-4 cursor-pointer rounded-4"
			style={{ backgroundColor: "#F9F9F9", border: "1px solid #E4E4E4" }}
			onClick={vendordetails}
		>
			<div className="flex justify-content-between">
				<div className="flex text-sm gap-4 w-75">
					<Image src={images.Brand} height={78} width={78}></Image>
					<table className="table table-borderless text-sm table-responsive transparent-table">
						<thead>
							<tr className="text-sm">
								<td
									className="w-25"
									style={{ color: "#8B8C89", fontWeight: "400" }}
								>
									Brand Name
								</td>
								<td style={{ color: "#8B8C89", fontWeight: "400" }}>
									Contacts
								</td>
							</tr>
						</thead>
						<tbody>
							<tr className="fs-6">
								<td
									className="fw-semibold"
									style={{ color: "#000000", fontWeight: "600" }}
								>
									24 Mantra Organic
								</td>
								<td>
									<div
										className="flex align-items-center gap-2"
										style={{ fontSize: "15px" }}
									>
										<div className="border rounded-3 p-1 px-2">
											thisarshadq812@gmail.com
										</div>
										<div className="border rounded-3 p-1 px-2">
											+91 9823198910
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div>
					<table className="table table-borderless text-center transparent-table">
						<thead>
							<tr className="text-sm">
								<td style={{ color: "#8B8C89", fontWeight: "400" }}>
									Payables
								</td>
								<td style={{ color: "#8B8C89", fontWeight: "400" }}>
									Total Products
								</td>
							</tr>
						</thead>
						<tbody>
							<tr className="fw-semibold text-3xl">
								<td style={{ color: "#000000", fontWeight: "600" }}>₹ 2000</td>
								<td style={{ color: "#000000", fontWeight: "600" }}>40</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div
				className="flex justify-content-between align-items-center rounded-2 p-2 px-3"
				style={{ backgroundColor: "#FCFCFC", border: "1px solid #E4E4E4" }}
			>
				<div className="ml-5 text-sm" style={{ color: "#989898" }}>
					Plot no. 11, Kruthika layout, Image Gardens Road, Hyderabad, A.P. -
					500081.
				</div>
				<div className="flex gap-3 text-sm align-items-center">
					<span style={{ color: "#9C9C9C" }}>PORTAL STATUS</span>
					<Button
						variant=""
						className="px-3 text-sm text-white rounded-3"
						style={{ backgroundColor: "#00B4D8" }}
					>
						Active
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ViewBrandVendor;
