export const ROUTES = {
	MAIN: "/",
	LOGIN: "/login",
	REGISTER: "/register",
	PRODUCTS: "/products",
	CATEGORIES: "/categories",
	BRANDS: "/brands",
	ADD_EDIT_PRODUCT: "/products/add-edit",
	ADJUSTMENTS:"/Adjustments",
	ADDADJUSTMENTS:"/Adjustments/Add-edit",
	VENDOR:"/Vendor",
	VENDORDETAILS:"/Vendor-Details",
	VENDORDETAILSADDPRODUCT:"/Vendor-Details/Add-Product",
	PURCHASEORDERS:"/Purchase-Orders",
	NEWPURCHASEORDERS:"/New-Purchase-Order",
	EDITPURCHASEORDER:"/Purchase-Orders/Edit-Orders",
	SENDMAIL:"/New-Purchase-Order/Send-Mail",
	BILLS:"/AllBills",
	ENC_DEC:"/Enc_Dec"
};
