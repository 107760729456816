import { END_POINTS } from "../utils/ApiEndpoints";
import ApiService from "./ApiService";
import {
  LOGIN_PAYLOAD_TYPE,
  LOGOUT_PAYLOAD_TYPE,
  REGISTER_PAYLOAD_TYPE,
} from "../utils/types";
import { getUserData } from "../utils/helper";

export const loginUser = async (data: LOGIN_PAYLOAD_TYPE) => {
  const URL = ApiService.getApiUrl() + END_POINTS.USER_LOGIN;
  const response = await ApiService.callPostApi(URL, data);
  return response;
};

export const registerUser = async (data: REGISTER_PAYLOAD_TYPE) => {
  const URL = ApiService.getApiUrl() + END_POINTS.USER_REGISTER;
  const response = await ApiService.callPostApi(URL, data);
  return response;
};

export const logoutUser = async (data: LOGOUT_PAYLOAD_TYPE) => {
  const URL = ApiService.getApiUrl() + END_POINTS.USER_LOGOUT;
  const response = await ApiService.callPostApi(URL, data);
  return response;
};

export const getToken = async () => {
  const response = await getUserData();
  if (response != null) {
    return response.token;
  }
};
