import TotalDetails from "../../components/TotalDetails/TotalDetails";
import { images } from "../../assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSearch,
	faPlus,
	faCloudArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Button";
import ViewBrandVendor from "../../components/ViewBrandvendor/ViewBrandVendor";
import Modal from "../../components/VendorModal/Modal";
import { useState } from "react";
import { Image } from "primereact/image";
import { Button as PrimeReactButton } from "primereact/button";

const BrandsVendor = () => {
	const [modalshow, setmodalshow] = useState(false);
	const [brandImage, setBrandImage] = useState<any>("");
	const [imageError] = useState("");

	const handleclose = () => {
		setmodalshow(false);
	};
	const handlesave = () => {};
	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			const newImages: Blob[] = Array.from(files);
			setBrandImage(newImages[0]);
		}
	};
	const onDeleteImage = () => {
		setBrandImage("");
	};
	return (
		<div>
			<div className="p-3">
				{/* Page Name */}
				<h4 className="mb-4 fw-bold">Brands (Vendor)</h4>
				{/* Total details */}
				<TotalDetails
					Items={[
						{
							backgroundcolor: "#BDD3FF",
							img: images.contact,
							title: "Total Vendors (Brands)",
							value: "120",
						},
						{
							backgroundcolor: "#FFDCDC",
							img: images.money,
							title: "Payables",
							value: "₹ 12,000",
						},
						{
							backgroundcolor: "#426E27",
							img: images.pending,
							title: "Purchase Order Pending",
							value: "100",
						},
					]}
				/>
				{/* Search and Addvendor button */}
				<div className="d-flex justify-content-between align-items-center	my-5">
					<div className="search-container" style={{ width: "35%" }}>
						<input
							type="search"
							className="search-input outline-none border rounded-3 p-2 px-3"
							placeholder="Search Brand"
						/>
						<FontAwesomeIcon
							icon={faSearch}
							color="#9F9F9F"
							className="mr-2 search-icon"
						/>
					</div>

					<div className="flex">
						<Button
							variant=""
							className="bg-black text-white px-4"
							onClick={() => {
								setmodalshow(true);
							}}
						>
							<FontAwesomeIcon icon={faPlus} className="mr-2" />
							Add Vendor
						</Button>
					</div>
				</div>
				{/* View All vendors */}
				<ViewBrandVendor />
			</div>
			<Modal
				title="Add Vendor"
				show={modalshow}
				handleClose={handleclose}
				handleSave={handlesave}
				size="lg"
				body={
					<div className="flex gap-4 flex-column">
						<div className="flex gap-4">
							<div
								className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
								style={{ border: "1px solid #E1E1E1", height: "100px" }}
							>
								<span
									className="fw-semibold"
									style={{ color: "#6C757D", fontSize: "12px" }}
								>
									Brand Name
								</span>
								<input
									type="text"
									placeholder="Add brand name"
									className="outline-none border-none mb-2"
								/>
							</div>
							<div
								className="flex flex-column rounded-3 w-50 p-2 justify-content-between"
								style={{ border: "1px solid #E1E1E1", height: "100px" }}
							>
								<span
									className="text-sm fw-semibold"
									style={{ color: "#6C757D" }}
								>
									Contacts
								</span>
								<input
									type="text"
									placeholder="Add contact number"
									className="outline-none border-none mb-2"
								/>
							</div>
						</div>
						<div className="flex mt-3 gap-4">
							<div className="flex flex-column justify-content-between w-50 gap-4">
								<div
									className="flex flex-column rounded-3 p-2 justify-content-between"
									style={{ border: "1px solid #E1E1E1", height: "100px" }}
								>
									<span
										className="text-sm fw-semibold"
										style={{ color: "#6C757D" }}
									>
										E-Mail Address
									</span>
									<input
										type="text"
										placeholder="Add E-mail Address"
										className="outline-none border-none mb-2"
									/>
								</div>
								<div
									className="flex flex-column rounded-3 p-2 justify-content-between"
									style={{ border: "1px solid #E1E1E1", height: "100px" }}
								>
									<span
										className="text-sm fw-semibold"
										style={{ color: "#6C757D" }}
									>
										Address
									</span>
									<input
										type="text"
										placeholder="Add Address"
										className="outline-none border-none mb-2"
									/>
								</div>
							</div>
							<div
								className="flex flex-column w-50 rounded-3 p-2 justify-content-between"
								style={{ border: "1px solid #E1E1E1" }}
							>
								<span
									className="text-sm fw-semibold"
									style={{ color: "#6C757D" }}
								>
									Brand Logo
								</span>
								<div className="h-100">
									{!brandImage ? (
										<div>
											<label
												htmlFor="image-upload"
												className="border-dashed border-light-subtle rounded d-flex flex-column justify-content-center align-items-center bg-white"
												style={{
													height: "200px",
													cursor: "pointer",
												}}
											>
												<input
													type="file"
													accept="image/*"
													onChange={handleImageChange}
													id="image-upload"
													style={{ visibility: "hidden" }}
												/>
												<FontAwesomeIcon icon={faCloudArrowUp} size="2xl" />
												<p className="w-75 text-center text-secondary">
													Drag and drop your image here or click to upload
												</p>
											</label>
											{imageError && (
												<small className="text-danger">{imageError}</small>
											)}
										</div>
									) : (
										<div className="image-upload">
											<div className="image-preview">
												<div className="">
													<Image
														src={URL.createObjectURL(brandImage)}
														alt={`Brand`}
														width="300"
														height="200"
														preview
														style={{
															objectFit: "contain",
														}}
													/>
													<PrimeReactButton
														icon="pi pi-trash"
														className="p-button-rounded p-button-danger p-button-outlined p-button-sm p-button-text"
														onClick={onDeleteImage}
													/>
												</div>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default BrandsVendor;
