import React from "react";
import { Modal } from "react-bootstrap";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface Props {
	title: string;
	show: boolean;
	handleClose: () => void;
	handleSave: () => void;
	body: React.ReactNode;
	loading?: boolean;
	btnName:String;
	size?: "sm" | "lg" | "xl";
}

const UiModal = (props: Props) => {
	const { title, show, handleClose, handleSave, body, loading, size,btnName } = props;
	return (
		<Modal size={size} show={show} onHide={handleClose} backdrop="static">
			<Modal.Header closeButton={!loading}>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="mb-3">{body}</Modal.Body>
			<Modal.Footer className="d-block">
				<Button variant="" className="bg-black text-white" onClick={handleSave} isLoading={loading}>
				<FontAwesomeIcon icon={faPlus} className="mr-2" /> {btnName}
				</Button>
				{/* <Button variant="secondary" onClick={handleClose} disabled={loading}>
					Close
				</Button> */}
			</Modal.Footer>
		</Modal>
	);
};

export default UiModal;
