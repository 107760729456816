import { initializeApp } from "firebase/app";
import Config from "./env.config";

const firebaseConfig = {
	apiKey: Config.FIREBASE_API_KEY,
	authDomain: Config.FIREBASE_AUTH_DOMAIN,
	projectId: Config.FIREBASE_PROJECT_ID,
	storageBucket: Config.FIREBASE_STORAGE_BUCKET,
	messagingSenderId: Config.FIREBASE_MESSAGING_SENDER_ID,
	appId: Config.FIREBASE_APP_ID,
};

initializeApp(firebaseConfig);
